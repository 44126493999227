import React from "react";
import ArrayField from '@rjsf/core/lib/components/fields/ArrayField'
import {
    getDefaultRegistry,
    retrieveSchema, toIdSchema
} from '@rjsf/core/lib/utils'
import {element} from './layout'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faTimes,
    faCaretUp,
    faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import {
    Button
} from "react-bulma-components";

function keyedToPlainFormData(keyedFormData) {
    return keyedFormData.map(keyedItem => keyedItem.item);
}

function compileFunc(func) {
    if (typeof func === 'string')
        return new Function("return " + func)()
    return func
}

function ArrayFieldTemplate(props) {
    let key = `array-table-${props.idSchema.$id}`,
        width = 100 / props.columns.length + '%',
        items = props.items,
        pagination = props.parent.state.pagination || props.pagination,
        pages = 0, page = 0, n_items = items.length,
        n_items_tot = props.parent.state.keyedFormData.length,
        data = props.uiSchema['ui:tableArray'];
    if (pagination) {
        pages = Math.ceil(items.length / pagination.items);
        page = Math.min((pagination.page || 0), pages - 1);
        let n = Number(pagination.items), i = page * n, j = i + n;
        items = items.filter((v, k) => (k >= i && k < j));
    }
    return (
        <fieldset className={props.className} id={props.idSchema.$id}>
            <label className='control-label'>{data.title || props.title}</label>
            <div
                className="table-responsive is-marginless is-borderless no-overflow" {...data.props}>
                {(pagination || props.searchable) ?
                    <div>
                        <div
                            className="columns is-marginless table-top-pagination">
                            <div className="column padding-top-0">
                                {pagination ?
                                    <div className="is-flex">
                                        <span>{window.lang('$Visualizza')}&nbsp;</span>
                                        <div
                                            className="select is-rounded is-small">
                                            <select
                                                value={pagination.items}
                                                onChange={(e) => {
                                                    let state = Object.assign({}, props.parent.state);
                                                    state.pagination = state.pagination || pagination;
                                                    if (state.pagination.items !== e.target.value) {
                                                        state.pagination.page = state.pagination.page * Number(state.pagination.items) / Number(e.target.value);
                                                        state.pagination.items = e.target.value;
                                                        props.parent.setState(state)
                                                    }
                                                }}>
                                                {pagination.options.map((v, i) => (
                                                    <option
                                                        key={key + '-pagination-' + i}
                                                        value={v}>{v}</option>))}
                                            </select>
                                        </div>
                                        <span>&nbsp;{window.lang('$elementi')}</span>
                                    </div> : null}
                            </div>
                            <div
                                className="column is-half padding-top-0">
                                {props.searchable ? <div className="is-flex">
                                    <span>{window.lang('$Cerca')}&nbsp;</span><input
                                    className="input is-rounded is-small"
                                    type="text"
                                    onChange={(e) => {
                                        let state = Object.assign({}, props.parent.state);
                                        state.search = e.target.value;
                                        state.pagination = state.pagination || pagination;
                                        if (state.pagination)
                                            state.pagination.page = 0;
                                        props.parent.setState(state)
                                    }}/>
                                </div> : null}
                            </div>
                        </div>
                    </div> : null}
                <table
                    key={key}
                    className="table table-condensed is-marginless has-column-border"
                    {...data.propsTable}
                    ref={elem => props.parent.tableRef = elem}>
                    <thead key={key + '-head'} {...data.propsTableHead}>
                    <tr key={key + '-head-row'}>
                        <th>
                            <div className="table-has-buttons-header">
                                {props.header_actions ? props.header_actions.map((d, i) => {
                                    return element(d, props.parent, key + '-head-action-' + i)
                                }) : null}
                            </div>
                        </th>
                        {props.columns.map((d, i) => {
                            return <th
                                width={(!d.hasOwnProperty('width') && width) || d.width || 0}
                                key={key + '-head-row-' + (i + 1)}>
                                <div
                                    className="is-flex align-center align-items-center">
                                <span
                                    key={key + '-head-row-' + (i + 1) + '-title'}
                                    className="is-full-width">
                                    {d.title}
                                </span>
                                    {props.orderable ? <span
                                        className="table-control-sort-div"
                                        key={key + '-head-row-' + (i + 1) + '-sort-icons'}>
                                        <FontAwesomeIcon
                                            icon={faCaretUp}
                                            className={"is-clickable" + (((props.parent.state.sort || {}).by === d.id && (props.parent.state.sort || {}).order === 'asc') ? " is-transparent" : "")}
                                            onClick={() => {
                                                let state = Object.assign({}, props.parent.state),
                                                    sort = props.parent.state.sort || {};
                                                state.sort = {
                                                    by: d.id,
                                                    order: 'asc'
                                                };
                                                if (state.sort.by !== sort.by || state.sort.order !== sort.order) {
                                                    props.parent.setState(state)
                                                } else {
                                                    state.sort = {};
                                                    props.parent.setState(state)
                                                }

                                            }}/>
                                        <FontAwesomeIcon
                                            icon={faCaretDown}
                                            className={"is-clickable" + (((props.parent.state.sort || {}).by === d.id && (props.parent.state.sort || {}).order === 'desc') ? " is-transparent" : "")}
                                            onClick={() => {
                                                let state = Object.assign({}, props.parent.state),
                                                    sort = props.parent.state.sort || {};
                                                state.sort = {
                                                    by: d.id,
                                                    order: 'desc'
                                                };
                                                if (state.sort.by !== sort.by || state.sort.order !== sort.order) {
                                                    props.parent.setState(state)
                                                } else {
                                                    state.sort = {};
                                                    props.parent.setState(state)
                                                }
                                            }}/>
                                </span> : null}
                                </div>
                                {d.headerInfo ? element(d.headerInfo, props.parent, key + '-head-row-' + (i + 1) + '-info') : null}
                            </th>
                        })}
                    </tr>
                    </thead>
                    <tbody key={key + '-body'}{...data.propsTableBody}>
                    {items && items.map(element => (element.children))}
                    </tbody>
                    {props.footer ?
                        <tfoot key={key + '-foot'} {...data.propsTableFoot}>
                        {props.footer.map((r, j) => {
                            return <tr key={key + '-foot-row-' + j}>
                                <th>
                                    <div className="table-has-buttons-header">
                                        {props.footer_actions ? props.footer_actions[j].map((d, i) => {
                                            return element(d, props.parent, key + '-foot-row-' + j + '-action-' + i)
                                        }) : null}
                                    </div>
                                </th>
                                {r.map((d, i) => {
                                    return <th
                                        width={(!d.hasOwnProperty('width') && width) || d.width || 0}
                                        key={key + '-foot-row-' + (i + 1)}>
                                        <div
                                            className={"is-flex align-center align-items-center " + (d.footClassNames || '')}>
                                            <span
                                                key={key + '-foot-row-' + (i + 1) + '-title'}
                                                className="is-full-width" {...d.props}>
                                                {d.hasOwnProperty("content") ? compileFunc(d.content)(props) : element(d, props.parent, key + '-foot-row-' + (i + 1) + '-element')}
                                            </span>
                                        </div>
                                    </th>
                                })}
                            </tr>
                        })}
                        </tfoot> : null}
                </table>
                {(pages > 1 || n_items_tot !== n_items) ?
                    <div>
                        <div className="columns table-bottom-pagination">
                            <div className="column">
                                {items.length ? (pagination === false ? null :
                                    <span>{window.lang('$table-view-1').format2([page * Number(pagination.items) + 1, page * Number(pagination.items) + items.length, n_items])}
                        </span>) : <span>{window.lang('$table-view-2')}</span>}
                                {(items.length && n_items_tot !== n_items) ?
                                    (pagination === false ?
                                        <span>{window.lang('$table-view-3').format2([n_items, n_items_tot])}</span> :
                                        <span>&nbsp;{window.lang('$table-view-4').format2([n_items_tot])}</span>) : null}
                            </div>
                            <div className="column is-half is-flex">
                                {pages > 1 ?
                                    <div
                                        className="buttons is-right is-full-width">
                                        <Button
                                            renderAs='div'
                                            key={key + '-pagination-button-back'}
                                            size="small"
                                            disabled={!(page > 0)}
                                            className="is-rounded"
                                            onClick={() => {
                                                let state = Object.assign({}, props.parent.state);
                                                state.pagination = state.pagination || pagination;
                                                state.pagination.page = Number(state.pagination.page || 1) - 1;
                                                props.parent.setState(state)
                                            }}>
                                            {window.lang('$Precedente')}
                                        </Button>
                                        {Array.from({length: Math.min(5, pages)}, (_, i) => i + Math.max(0, Math.min(page + 2, pages) - 5)).map(v => (
                                            <Button
                                                renderAs='div'
                                                key={key + '-pagination-button-page-' + v}
                                                size="small"
                                                className={"is-rounded" + (v === page ? " is-primary" : "")}
                                                onClick={() => {
                                                    let state = Object.assign({}, props.parent.state);
                                                    state.pagination = state.pagination || pagination;
                                                    state.pagination.page = v;
                                                    props.parent.setState(state)
                                                }}>{v + 1}</Button>
                                        ))}
                                        <Button
                                            renderAs='div'
                                            key={key + '-pagination-button-forward'}
                                            size="small"
                                            disabled={!((page + 1) < pages)}
                                            className="is-rounded"
                                            onClick={() => {
                                                let state = Object.assign({}, props.parent.state);
                                                state.pagination = state.pagination || pagination;
                                                state.pagination.page = (state.pagination.page || 0) + 1;
                                                props.parent.setState(state)
                                            }}>{window.lang('$Successivo')}</Button>
                                    </div> : null}
                            </div>
                        </div>
                    </div> : null}
            </div>
            {props.canAdd ?
                <span
                    key={key + '-add'}
                    className="icon has-background-primary action-icons is-clickable array-table-item-add-icon has-tooltip-arrow has-tooltip-top"
                    data-tooltip={window.lang('$table-item-add->tooltip')}
                    onClick={props.onAddClick}>
                    <FontAwesomeIcon icon={faPlus}/>
                </span> : null
            }
        </fieldset>
    );
}

export default class TableArrayField extends ArrayField {
    renderNormalArray() {
        const {
            schema,
            uiSchema,
            errorSchema,
            idSchema,
            name,
            required,
            disabled,
            readonly,
            autofocus,
            registry = getDefaultRegistry(),
            onBlur,
            onFocus,
            idPrefix,
            rawErrors,
        } = this.props;
        const {orderable, removable, pagination, searchable} = {
            orderable: true,
            removable: true,
            pagination: false,
            searchable: false,
            ...uiSchema["ui:options"],
        };
        const title = schema.title === undefined ? name : schema.title;
        const {rootSchema, fields, formContext} = registry;
        const {TitleField, DescriptionField} = fields;
        const itemsSchema = retrieveSchema(schema.items, rootSchema);
        const formData = keyedToPlainFormData(this.state.keyedFormData);
        let columns, footer, actions, footer_actions, header_actions,
            canAdd = this.canAddItem(formData),
            rows = uiSchema["ui:tableArray"].rows || {},
            itemName = uiSchema["ui:tableArray"].itemName || "Elemento";
        if (uiSchema["ui:tableArray"].hasOwnProperty('footer')) {
            footer = uiSchema["ui:tableArray"].footer
        }
        if (uiSchema["ui:tableArray"].hasOwnProperty('footer_actions')) {
            footer_actions = uiSchema["ui:tableArray"].footer_actions
        }
        if (uiSchema["ui:tableArray"].hasOwnProperty('header_actions')) {
            header_actions = uiSchema["ui:tableArray"].header_actions
        }
        if (uiSchema["ui:tableArray"].hasOwnProperty('columns')) {
            columns = uiSchema["ui:tableArray"].columns
        } else {
            columns = Object.keys(itemsSchema.properties);
            columns.sort();
            columns = columns.map(k => ({id: k}))
        }
        columns = columns.map(d => {
            if (!d.hasOwnProperty('title')) {
                let name = d.id;
                d.title = (itemsSchema.properties[name] !== undefined && retrieveSchema(itemsSchema.properties[name], rootSchema).title) || name
            }
            return d
        });
        if (uiSchema["ui:tableArray"].hasOwnProperty('actions')) {
            actions = uiSchema["ui:tableArray"].actions
        }
        let items = this.state.keyedFormData.map((v, i) => (Object.assign({index: i}, v)));
        if (this.state.search) {
            let re = this.state.search.split(' ').filter(v => v).map(v => new RegExp(v, "i"));
            items = items.filter(v => {
                return re.every(r => columns.some(c => (c.searchable && ("" + v.item[c.id]).search(r) !== -1)))
            });
        }
        if (this.state.sort) {
            let by = this.state.sort.by,
                func = compileFunc(((uiSchema["ui:tableArray"]['sort-trans'] || {})[by] || "(v, item)=>(''+(v || ''))"));
            items = items.sort((a, b) => {
                a = func(a.item[by], a.item);
                b = func(b.item[by], b.item);
                if (a > b) {
                    return this.state.sort.order !== 'desc' ? -1 : 1;
                }
                if (b > a) {
                    return this.state.sort.order !== 'desc' ? 1 : -1;
                }
                return 0;
            });
        }
        const arrayProps = {
            parent: this,
            footer: footer,
            header_actions: header_actions,
            footer_actions: footer_actions,
            columns: columns,
            canAdd: canAdd,
            hasButtons: removable || (actions || []).length,
            items: items.map(keyedItem => {
                const {key, item, index} = keyedItem;
                const itemSchema = retrieveSchema(schema.items, rootSchema, item);
                const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;
                const itemIdPrefix = idSchema.$id + "_" + index;
                const itemIdSchema = toIdSchema(
                    itemSchema,
                    itemIdPrefix,
                    rootSchema,
                    item,
                    idPrefix
                );
                let canRemove = true, uiSchemaItems = uiSchema.items;
                if (rows.hasOwnProperty(index))
                    canRemove = !!rows[index].removable;

                if (uiSchema.getItemsUiSchema)
                    uiSchemaItems = compileFunc(uiSchema.getItemsUiSchema)(this, index, formData);
                return this.renderArrayFieldItem({
                    columns,
                    itemName,
                    actions,
                    key,
                    index,
                    items: (rows[index] || {}).items || {},
                    canAdd: canAdd,
                    canRemove: canRemove,
                    canMoveUp: index > 0,
                    canMoveDown: index < formData.length - 1,
                    itemSchema: itemSchema,
                    itemIdSchema,
                    itemErrorSchema,
                    itemData: item,
                    itemUiSchema: uiSchemaItems,
                    autofocus: autofocus && index === 0,
                    onBlur,
                    onFocus,
                    row: (rows[index] || {}),
                    transformData: uiSchema["ui:tableArray"].transformData
                });
            }),
            className: `field field-array field-array-of-${itemsSchema.type}` + (uiSchema["ui:tableArray"].classNames ? " " + uiSchema["ui:tableArray"].classNames : ""),
            DescriptionField,
            disabled,
            idSchema,
            uiSchema,
            onAddClick: this.onAddClick,
            readonly,
            required,
            schema,
            title,
            TitleField,
            formContext,
            formData,
            rawErrors,
            registry,
            orderable,
            pagination,
            searchable
        };

        // Check if a custom render function was passed in
        const Component =
            uiSchema["ui:ArrayFieldTemplate"] ||
            ArrayFieldTemplate;
        return <Component {...arrayProps} />;
    }

    transformData(func, formData, index) {
        formData = formData || this.props.formData;
        if (func)
            formData = compileFunc(func)(formData, index, this);
        return formData
    }

    renderArrayFieldItem(props) {
        const {
            columns,
            actions = [],
            itemName = "Elemento",
            key,
            index,
            canRemove = true,
            canMoveUp = true,
            canMoveDown = true,
            itemSchema,
            itemData,
            itemUiSchema,
            itemIdSchema,
            itemErrorSchema,
            onBlur,
            onFocus,
            items,
            row,
            transformData = null
        } = props;
        const {
            disabled,
            readonly,
            uiSchema,
            registry = getDefaultRegistry(),
        } = this.props;
        const {fields: {SchemaField}} = registry;
        const {orderable, removable} = {
            orderable: true,
            removable: true,
            ...uiSchema["ui:options"],
        };
        const has = {
            moveUp: orderable && canMoveUp,
            moveDown: orderable && canMoveDown,
            remove: removable && canRemove,
        };
        has.toolbar = Object.keys(has).some(key => has[key]);
        let getContent = (d, i, keySuffix) => {
            let name = d.id, {rootSchema} = this.props.registry,
                schema = retrieveSchema(itemSchema, rootSchema).properties[name];
            i++;
            keySuffix = keySuffix || '';
            const {formData, onChange} = this.props;
            let content, value,
                onPropertyChange = (name) => ((value, errorSchema) => {
                    let newFormData = formData.map((item, i) => {
                        if (index !== i)
                            return item;
                        item = Object.assign({}, item);
                        item[name] = value;
                        return item
                    });
                    newFormData = this.transformData(transformData, newFormData, index);
                    onChange(
                        newFormData,
                        errorSchema &&
                        this.props.errorSchema && {
                            ...this.props.errorSchema,
                            [index]: errorSchema,
                        }
                    );
                });

            if (schema) {
                value = (itemData || {})[name];
                content = <SchemaField
                    key={key + keySuffix + '-' + i + '-content'}
                    name={name}
                    schema={schema}
                    uiSchema={row.uiSchema || d.uiSchema || (itemUiSchema || {})[name]}
                    errorSchema={(itemErrorSchema || {})[name]}
                    idSchema={(itemIdSchema || {})[name]}
                    formData={value}
                    tableData={formData}
                    index={index}
                    parent={this}
                    onChange={onPropertyChange(name)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    registry={this.props.registry}
                    disabled={disabled}
                    readonly={readonly} {...(items[name] || {}).props}/>
            } else if (!d.hasOwnProperty('id')) {
                content = element(d, this, key + keySuffix + '-' + i + '-content', {
                    index: index,
                    key: key,
                    onPropertyChange: onPropertyChange,
                    itemUiSchema: itemUiSchema,
                    row,
                    itemData,
                    itemIdSchema,
                    schema: retrieveSchema(itemSchema, rootSchema)
                })
            }
            return content
        }
        let width = 100 / props.columns.length + '%',
            children = columns.map((d, i) => {
                return <td key={key + '-' + i}
                           width={(!d.hasOwnProperty('width') && width) || d.width || 0}>
                    {getContent(d, i)}
                </td>
            });
        let buttons = actions.map((d, i) => {
            let icon = getContent(d, i, '-action');
            return icon ? <span
                key={key + '-action-' + i}
                className="icon action-icons small-icon is-clickable has-background-primary" {...d.action}>
            {icon}</span> : null
        });
        if (has.remove)
            buttons.push(
                <span
                    key={key + '-action-remove'}
                    className="icon has-background-danger action-icons small-icon is-clickable has-tooltip-arrow has-tooltip-right has-tooltip-bottom"
                    data-tooltip={"Rimuovi " + itemName}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={this.onDropIndexClick(index, transformData)}/>
                </span>
            );
        if (buttons.length) {
            buttons = <td key={key + '-buttons'}>
                <div key={key + '-actions-'} className="field is-grouped">
                    {buttons}
                </div>
            </td>;
        } else {
            buttons = <td key={key + '-buttons'}/>
        }
        if (buttons)
            children = [buttons].concat(children);
        return {
            children: (<tr
                key={key}
                className={"table-array-row-index-" + index}
                {...row.props}>
                {children}
            </tr>),
            className: "array-row",
            disabled,
            hasToolbar: has.toolbar,
            hasMoveUp: has.moveUp,
            hasMoveDown: has.moveDown,
            hasRemove: has.remove,
            index,
            key,
            onAddIndexClick: this.onAddIndexClick,
            onDropIndexClick: this.onDropIndexClick,
            onReorderClick: this.onReorderClick,
            readonly,
        };
    }

    onDropIndexClick = (index, transformData) => {
        return event => {
            if (event) {
                event.preventDefault();
            }
            const {onChange} = this.props;
            const {keyedFormData} = this.state;
            // refs #195: revalidate to ensure properly reindexing errors
            let newErrorSchema;
            if (this.props.errorSchema) {
                newErrorSchema = {};
                const errorSchema = this.props.errorSchema;
                for (let i in errorSchema) {
                    i = parseInt(i);
                    if (i < index) {
                        newErrorSchema[i] = errorSchema[i];
                    } else if (i > index) {
                        newErrorSchema[i - 1] = errorSchema[i];
                    }
                }
            }
            const newKeyedFormData = keyedFormData.filter((_, i) => i !== index);
            this.setState(
                {
                    keyedFormData: newKeyedFormData,
                    updatedKeyedFormData: true,
                },
                () => onChange(this.transformData(transformData, keyedToPlainFormData(newKeyedFormData)), newErrorSchema)
            );
        };
    };
};
