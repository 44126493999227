import React from "react";
import {Button} from "react-bulma-components"

function ArrayFieldTitle({TitleField, idSchema, title, required, className}) {
    if (!title) {
        return null;
    }
    const id = `${idSchema.$id}__title`;
    return <div className={className} id={id}>{title}</div>;
}

function ArrayFieldDescription({DescriptionField, idSchema, description}) {
    if (!description) {
        return null;
    }
    const id = `${idSchema.$id}__description`;
    return <DescriptionField id={id} description={description}/>;
}

export default function ArrayField(props) {
    let rows = (((props.uiSchema || {}).items || {})["ui:array"] || {}).rows || [];
    return (
        <fieldset className={props.className} id={props.idSchema.$id}>
            <div className="vertical-wrapper">
                <div
                    className="vertical-fix is-flex array-control is-mobile is-marginless"
                    key={`array-item-add-${props.idSchema.$id}`}>
                    {props.canAdd && (
                        <Button
                            onClick={props.onAddClick} renderAs='div'
                            className="delete is-rounded is-primary array-item-add level-left"
                            disabled={props.disabled || props.readonly}/>
                    )}<ArrayFieldTitle
                    key={`array-field-title-${props.idSchema.$id}`}
                    TitleField={props.TitleField}
                    idSchema={props.idSchema}
                    title={props.uiSchema["ui:title"] || props.title}
                    required={props.required}
                    className="card-header-title"
                />{(props.uiSchema["ui:description"] || props.schema.description) && (
                    <ArrayFieldDescription
                        key={`array-field-description-${props.idSchema.$id}`}
                        DescriptionField={props.DescriptionField}
                        idSchema={props.idSchema}
                        description={
                            props.uiSchema["ui:description"] || props.schema.description
                        }
                    />
                )}
                </div>
                <div
                    className="vertical-content array-item-list"
                    key={`array-item-list-${props.idSchema.$id}`}>
                    {props.items &&
                    props.items.map(element => {
                        let remove = (rows[element.index] || {}).removable;
                        remove = element.hasRemove && (remove === undefined || remove);
                        return <div key={element.key}
                                    className={element.className}>
                            {element.children}
                            {remove &&
                            <Button
                                renderAs='div'
                                className="delete is-danger is-rounded delete-array-item"
                                onClick={element.onDropIndexClick(element.index)}/>
                            }
                        </div>
                    })}
                </div>
            </div>
        </fieldset>
    );
}
