import React from 'react';
import ObjectField
    from '@rjsf/core/lib/components/fields/ObjectField'
import {retrieveSchema} from '@rjsf/core/lib/utils'

import {
    faChevronDown,
    faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {element} from "./layout"

function getTitle(item, properties, rootSchema) {
    if (item.hasOwnProperty("text")) {
        return item.text
    }
    return (properties[item.id] !== undefined && retrieveSchema(properties[item.id], rootSchema).title) || item.id;
}

function compileFunc(func) {
    if (typeof func === 'string')
        return new Function("return " + func)()
    return func
}

function formatCell(parent, item_type, item, properties, rootSchema, data, plan_id, id, index, key) {
    let title = getTitle(item, properties, rootSchema),
        content = title,
        onClick,
        props,
        data_feature = undefined,
        classes = "plan-" + item_type + " " + (index % 2 === 0 ? "even" : "odd");
    key += '-' + index;
    if (item.classes)
        classes = classes + " " + item.classes;
    if (item.children) {
        classes += " is-clickable";
        onClick = (e) => {
            e.preventDefault();
            let state = Object.assign({}, parent.state);
            state[id] = !parent.state[id];
            parent.setState(state);
        }
    }

    if (!data) {
        let {className = '', ...p} = item.propsTitle || {};
        props = p;
        if (className)
            classes += ' ' + className;
        content = [content];
        if (item_type === "price") {
            content = [<span
                key={key + '-price'}
                className="plan-price-amount plan-price-amount-header" {...item.titleProps}>
                {item.title ? content[0] : '&nbsp;'}
            </span>];
        }
        if (item.children) {
            content = [
                <span key={key + '-icon'} onClick={onClick}
                      className="icon plan-icon is-pulled-left" {...item.titleProps}>
                    <FontAwesomeIcon
                        icon={parent.state[id] ? faChevronUp : faChevronDown}
                    />&nbsp;
                </span>,
                <span key={key + '-title'} onClick={onClick}>
                    {content}
                </span>
            ]
        }
        if (item.titleInfo) {
            content.push(element(item.titleInfo, parent, key + '-info'))
        }
        onClick = null;
    } else {
        let {className = '', ...p} = item.propsItem || {};
        props = p;
        if (className)
            classes += ' ' + className;
        content = data[item.id] || "-";
        if (item.transformContent)
            content = compileFunc(item.transformContent)(content, data);
        if (item.element) {
            let name = item.id;
            const {
                idSchema, errorSchema, uiSchema, onBlur, onFocus,
                schema
            } = parent.props;
            const {fields} = parent.props.registry;
            const {SchemaField} = fields;
            content =
                <SchemaField
                    key={key + '-button-' + name}
                    id={key + '-button-' + name}
                    name={name}
                    required={parent.isRequired(name)}
                    schema={schema.items.properties[name]}
                    uiSchema={item.uiSchema || uiSchema[name]}
                    errorSchema={errorSchema[name]}
                    idSchema={idSchema[name]}
                    onChange={parent.onPropertyChange(name)}
                    formData={(data || {})[name]}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    registry={parent.props.registry}
                />
        }

        if (item_type === "price") {
            content = [
                <span key={key + '-price'} className="plan-price-amount">
                    {item.currency ?
                        <span key={key + '-price-currency'}
                              className="plan-price-currency">
                            {item.currency}
                        </span> : null}
                    {content}
                </span>,
                item.unit ? "/" + item.unit : null];

        } else if (item_type === "item") {
            data_feature = title
        }
    }
    return <div key={key} onClick={onClick} className={classes}
                data-feature={data_feature} {...props}>
        {content}
    </div>
}

function formatCells(parent, keys, item_type, items, properties, rootSchema, data, plan_id, key, parent_id) {
    return (items || []).filter(item => {
        return keys.includes(item.id)
    }).map((item, i) => {
        let id = (parent_id || []).concat(item.id),
            children = (item.children && parent.state[String(id)]) ?
                <div key={key + '-' + (i + 1)} className='plan-children'>
                    {formatCells(parent, keys, item_type, item.children, properties, rootSchema, data, plan_id, key + '-' + (i + 1), id)}
                </div> : null;
        return [
            formatCell(parent, item_type, item, properties, rootSchema, data, plan_id, id, i, key + '-0'),
            children
        ]
    })
}

function formatPlan(classNames, parent, keys, options, properties, rootSchema, id, data, plan_id) {
    classNames = classNames ? " " + classNames : "";
    return <div key={id} className={"pricing-plan" + classNames}>
        {options.rows.map(v => (
            [
                formatCells(parent, keys, "header", v.header, properties, rootSchema, data, plan_id, id + '-header'),
                formatCells(parent, keys, "price", v.price, properties, rootSchema, data, plan_id, id + '-price'),
                <div key={id + '-items'} className="plan-items">
                    {formatCells(parent, keys, "item", v.items, properties, rootSchema, data, plan_id, id + '-items-content')}
                </div>,
                formatCells(parent, keys, "footer", v.footer, properties, rootSchema, data, plan_id, id + '-footer')]
        ))}
    </div>
}

function sortPlans(data, options) {
    let scores = data.map((d, i) => {
        return options.id.map(k => d[k]).concat([i])
    });
    scores.sort((a, b) => {
        for (let i = 0, j = a.length; i < j; i++) {
            if (a[i] !== b[i]) return a[i] - b[i];
        }
        return 0
    });
    if (options.criteria === "max") {
        scores.reverse()
    }
    return scores
}

function findBest(data, options) {
    let scores = sortPlans(data, options);
    return options.classes.map((className, i) => {
        let v = scores[i];
        return [v[v.length - 1], className]
    })
}

function table(parent, formData, schema, rootSchema, uiSchema, id) {
    if (formData !== undefined && formData.length > 0) {
        let properties = schema.items.properties,
            data = formData,
            options = uiSchema.price || {},
            keys = {}, classNames = {};
        if (options.sort) {
            data = sortPlans(data, options.sort).map(v => {
                return data[v[v.length - 1]]
            })
        }
        if (options.worst) {
            findBest(data, options.worst).forEach(v => {
                classNames[v[0]] = v[1]
            })
        }
        if (options.best) {
            findBest(data, options.best).forEach(v => {
                classNames[v[0]] = v[1]
            })
        }
        if (options.numbers) {
            data = data.map(d => {
                let row = {};
                Object.keys(d).forEach(k => {
                    let v = d[k];
                    if (typeof v == 'number') {
                        v = Math.round(v) || "-";
                        if (typeof v == 'number') v = v.toLocaleString('it');
                    }
                    row[k] = v
                });
                return row
            });
        }
        data.forEach(d => {
            Object.keys(d).forEach(k => {
                keys[k] = null
            })
        });
        keys = Object.keys(keys);
        return <div key={id}
                    className="pricing-table is-comparative" {...options.props}>
            {formatPlan("is-features", parent, keys, options, properties, rootSchema, id + '-features')}
            {data.map((d, i) => {
                return formatPlan(classNames[i] || options.planClassNames, parent, keys, options, properties, rootSchema, id + '-plan-' + i, d, i)
            })}
        </div>;
    }
}

export default class PriceTableField extends ObjectField {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {idSchema, required, uiSchema, formData} = this.props;
        const {rootSchema, fields, formContext} = this.props.registry;
        const {TitleField, DescriptionField} = fields;
        const schema = retrieveSchema(this.props.schema, rootSchema);
        let title;
        if (uiSchema !== undefined && uiSchema.hasOwnProperty('ui:title')) {
            title = uiSchema['ui:title'];
        } else {
            title = (schema.title === undefined) ? '' : schema.title;
        }
        return (
            <div key={`${idSchema.$id}__container`}>
                {title ? <TitleField
                    id={`${idSchema.$id}__title`}
                    title={title}
                    className='control-label'
                    required={required}
                    formContext={formContext}/> : null}
                {schema.description ?
                    <DescriptionField
                        id={`${idSchema.$id}__description`}
                        description={schema.description}
                        formContext={formContext}/> : null}
                {table(this, formData, schema, rootSchema, uiSchema, idSchema.$id)}
            </div>
        )
    };
}