import React from 'react';
import {Modal, Button} from 'react-bulma-components';
import {retrieveSchema} from "@rjsf/core/lib/utils";
import ObjectField
    from '@rjsf/core/lib/components/fields/ObjectField'

export default class ModalField extends ObjectField {
    state = {
        show: false,
    };

    open = () => this.setState({show: true});
    close = () => this.setState({show: false});

    render() {
        const {
            idSchema, required, formData, uiSchema, errorSchema, disabled,
            readonly, onBlur, name
        } = this.props;
        const {rootSchema} = this.props.registry;
        const schema = retrieveSchema(this.props.schema, rootSchema);
        let title, modal = uiSchema["ui:modal"] || {};
        if (uiSchema !== undefined && uiSchema.hasOwnProperty('ui:title')) {
            title = uiSchema['ui:title'];
        } else {
            title = (schema.title === undefined) ? '' : schema.title;
        }
        if (modal.icon) {
            title = [<i {...modal.icon}></i>, title]
        }
        return (
            <div>
                <Button size="small" className="is-rounded" renderAs='div'
                        onClick={this.open}>{title}</Button>
                <Modal show={this.state.show}
                       onClose={this.close} {...modal}>
                    <Modal.Content {...modal.content}>
                        <ObjectField
                            name={name}
                            required={required}
                            schema={schema}
                            uiSchema={uiSchema}
                            errorSchema={errorSchema}
                            idSchema={idSchema}
                            formData={formData}
                            onChange={this.props.onChange}
                            onBlur={onBlur}
                            registry={this.props.registry}
                            disabled={disabled}
                            readonly={readonly}/>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}
