import React from 'react';
import ReactDOM from 'react-dom'
import ObjectField
    from '@rjsf/core/lib/components/fields/ObjectField'
import {retrieveSchema} from "@rjsf/core/lib/utils";
import {
    ReflexContainer, ReflexSplitter, ReflexElement
} from 'react-reflex'
import {Button, Message} from "react-bulma-components";

function* split(arr) {
    if (arr !== undefined && arr.length > 0) {
        yield arr[0];
        for (let i = 1; i < arr.length; i++) {
            yield null;
            yield arr[i];
        }
    }
}

function formatSchemaField(self, schema, name) {
    const {
        idSchema, formData, uiSchema, errorSchema, onBlur, disabled,
        readonly, onFocus
    } = self.props;
    const {SchemaField} = self.props.registry.fields;
    return <SchemaField
        name={name}
        required={self.isRequired(name)}
        schema={schema.properties[name]}
        uiSchema={uiSchema[name]}
        errorSchema={errorSchema[name]}
        idSchema={idSchema[name]}
        formData={formData[name]}
        onChange={self.onPropertyChange(name)}
        onBlur={onBlur}
        onFocus={onFocus}
        registry={self.props.registry}
        disabled={disabled}
        readonly={readonly}/>
}

class ControlledElement extends React.Component {
    constructor(props) {
        super(props);
        this.onLockSizeClicked = this.onLockSizeClicked.bind(this);
        this.onMinimizeClicked = this.onMinimizeClicked.bind(this);
        this.onMaximizeClicked = this.onMaximizeClicked.bind(this);
        this.state = {size: -1}
    }

    onLockSizeClicked() {
        this.props.onLockSize({
            locked: this.props.sizeLocked,
            paneId: this.props.id,
            size: this.getSize()
        })
    }

    onMinimizeClicked() {
        const currentSize = this.getSize();
        const update = (size) => {

            return new Promise((resolve) => {
                this.setState(Object.assign({},
                    this.state, {
                        size: size < 37 ? 37 : size
                    }), () => resolve())
            })
        };
        const done = (from, to) => {
            return from < to
        };
        this.animate(currentSize, 37, -8, done, update)
    }

    onMaximizeClicked() {
        const currentSize = this.getSize();
        const update = (size) => {
            return new Promise((resolve) => {
                this.setState(Object.assign({},
                    this.state, {
                        size
                    }), () => resolve())
            })
        };
        const done = (from, to) => {
            return from > to
        };
        this.animate(currentSize, window.screen.height, window.screen.height / 50, done, update)
    }

    getSize() {
        const domElement = ReactDOM.findDOMNode(this);
        switch (this.props.orientation) {
            case 'horizontal':
                return domElement.offsetHeight;
            case 'vertical':
                return domElement.offsetWidth;
            default:
                return 0
        }
    }

    animate(from, to, step, done, fn) {
        const stepFn = () => {
            if (!done(from, to)) {
                fn(from += step).then(() => {
                    setTimeout(stepFn, 8)
                })
            }
        };
        stepFn()
    }

    render() {
        let parent = this.props.parent, cell = this.props.cell_id;
        const {
            idSchema, formData, uiSchema, errorSchema, onBlur, disabled,
            readonly, schema, onFocus
        } = parent.props;
        const {rootSchema, fields} = parent.props.registry;
        const {SchemaField} = fields;
        let title, required = parent.isRequired(cell),
            cell_uiSchema = uiSchema !== undefined ? uiSchema[cell] : undefined,
            cell_schema = retrieveSchema(schema.properties[cell], rootSchema);
        if (cell_uiSchema !== undefined && cell_uiSchema.hasOwnProperty('ui:title')) {
            title = cell_uiSchema['ui:title'];
        } else {
            title = (cell_schema.title === undefined) ? '' : cell_schema.title;
        }
        title = this.props.title || title;
        return (
            <ReflexElement size={this.state.size} {...this.props}>
                <Message className="pane-content">
                    <Message.Header
                        className="pane-control"
                        onDoubleClick={this.onMaximizeClicked}>
                        <p>{title}</p>
                        <Button
                            className="is-pulled-right pr-1 is-rounded"
                            size="small"
                            renderAs='div'
                            onClick={this.onMaximizeClicked}
                            color="info">Espandi</Button>
                    </Message.Header>
                    <Message.Body className="ctrl-pane-content">
                        <SchemaField
                            name={cell}
                            required={required}
                            schema={schema.properties[cell]}
                            uiSchema={uiSchema[cell]}
                            errorSchema={errorSchema[cell]}
                            idSchema={idSchema[cell]}
                            formData={formData[cell]}
                            onChange={parent.onPropertyChange(cell)}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            registry={parent.props.registry}
                            disabled={disabled}
                            readonly={readonly}/>
                    </Message.Body>
                </Message>
            </ReflexElement>
        )
    }
}

function cells(self, schema, rootSchema, row) {
    if (row.length === 1) {
        return formatSchemaField(self, schema, row[0].id)
    } else {
        row = Array.from(split(row));
        let n = row.length - 1;
        return row.map((cell, index) => {
            if (cell === null) {
                return <ReflexSplitter propagate={true}/>
            } else {
                if (!self.state.hasOwnProperty(cell.id)) {
                    self.state[cell.id] = Object.assign({}, {
                        onLockSize: self.onLockSize,
                        sizeLocked: false,
                        direction: index === 0 ? 1 : index === n ? -1 : [1, -1],
                        minSize: 37
                    }, cell);
                }
                return <ControlledElement
                    parent={self}
                    cell_id={cell.id} {...self.state[cell.id]}
                />
            }
        });
    }
}

export default class FlexGridField extends ObjectField {
    constructor(props) {
        super(props);
        this.state = {};
        this.onLockSize = this.onLockSize.bind(this);
    }

    onLockSize(data) {
        const locked = !this.state[data.paneId].sizeLocked;
        this.state[data.paneId].sizeLocked = locked;
        if (locked) {
            this.state[data.paneId].minSize = data.size;
            this.state[data.paneId].maxSize = data.size;
        } else {
            this.state[data.paneId].minSize = 37;
            this.state[data.paneId].maxSize = Number.MAX_VALUE
        }
        this.setState(Object.assign({}, this.state))
    }

    render() {
        const {idSchema, required, uiSchema} = this.props;
        const {rootSchema, fields, formContext} = this.props.registry;
        const {TitleField, DescriptionField} = fields;
        const schema = retrieveSchema(this.props.schema, rootSchema);
        let title;
        if (uiSchema !== undefined && uiSchema.hasOwnProperty('ui:title')) {
            title = uiSchema['ui:title'];
        } else {
            title = (schema.title === undefined) ? '' : schema.title;
        }
        const self = this;
        return (
            <fieldset>
                {title ? <TitleField
                    id={`${idSchema.$id}__title`}
                    title={title}
                    required={required}
                    formContext={formContext}/> : null}
                {schema.description ?
                    <DescriptionField
                        id={`${idSchema.$id}__description`}
                        description={schema.description}
                        formContext={formContext}/> : null}
                <ReflexContainer orientation="vertical">{
                    Array.from(split(uiSchema['ui:flex_grid'].layout)).map(row => {
                        if (row === null) {
                            return <ReflexSplitter/>
                        } else {
                            return <ReflexElement flex={row.flex}>
                                <div className="pane-content">
                                    <ReflexContainer orientation="horizontal">
                                        {cells(self, schema, rootSchema, row.col)}
                                    </ReflexContainer>
                                </div>
                            </ReflexElement>
                        }
                    })
                }</ReflexContainer>
            </fieldset>);
    };
}
