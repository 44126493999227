import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'fontsource-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import Form from "@rjsf/core/lib";
import PriceTableField from "./price";
import FlexGridField from "./flex_layout";
import FieldLayout from "./layout"
import ImageUploadWidget from "./image";
import ModalField from "./modal";
import PDFField from "./pdf"
import ArrayFieldTemplate from "./array"
import BaseInput from "./base";
import TableArrayField from "./table"
import RadioWidget from "./radio";
import CheckboxWidget from "./check";
import DatePicker from "./date";
import CheckboxesWidget from "./checks"
import ReCAPTCHAWidget from "./recaptcha"
import LateralArray from "./arrayLateral"
import ListinoWidget from "./listino"
import TextareaWidget from "./textarea"
import _ from "lodash"
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import 'bootswatch/paper/bootstrap.min.css';
import 'react-reflex/styles.css'
import './custom.css';

String.prototype.format2 = function (args) {
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
};

const ProgressBar = function (props) {
    return props.value ?
        <progress className="progress is-medium is-primary" max="50">
            80%
        </progress> : null
};

const fields = {
    pdf: PDFField,
    price: PriceTableField,
    layout: FieldLayout,
    flex_grid: FlexGridField,
    listino: ListinoWidget,
    modal: ModalField,
    tableArray: TableArrayField,
    lateralArray: LateralArray
};
const widgets = {
    ReCAPTCHA: ReCAPTCHAWidget,
    radio: RadioWidget,
    checkbox: CheckboxWidget,
    checkboxes: CheckboxesWidget,
    ImageUpload: ImageUploadWidget,
    textarea: TextareaWidget,
    rdp: DatePicker,
    BaseInput: BaseInput,
    ProgressBar: ProgressBar
};
var form;
const log = (type) => console.log.bind(console, type);

function equalFormData(formData) {
    return ['cliente', 'finanziamento', 'oggetto', 'offerte', 'buy', 'licences', 'settings'].reduce((r, k) => {
        return r && JSON.stringify(formData[k]) === JSON.stringify(window.formData[k])
    }, true);
}

const getForm = () => (form);

function transformErrors(errors) {
    window.errors = errors
    return errors
}

const onChange = async ({formData}, callback) => {
    let b = JSON.stringify(formData);
    formData = validateFormData(formData);
    b = b === JSON.stringify(formData);
    if (b && formData.running) {
        let data = Object.assign({}, formData, {result: []});
        form.setState(Object.assign({}, form.state, {formData: data}), () => {
            fetchAPI(true, '/api', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        'kwargs': {
                            'inputs': {
                                'data': {
                                    cliente: formData.cliente,
                                    oggetto: formData.oggetto,
                                    finanziamento: formData.finanziamento,
                                    offerte: formData.offerte
                                }
                            },
                            'outputs': ['json_tables'],
                            'select_output_kw': {
                                'keys': ['json_tables'],
                                'output_type': 'values'
                            }
                        }
                    }
                )
            }, res => {
                let data = Object.assign({}, form.state.formData);
                data.running = false;
                data.result = (res.return || []).map(d => {
                    d.dettagli = Object.assign(
                        {}, data.settings, d.dettagli || {}
                    );
                    return d
                });
                data = validateFormData(data);
                window.formData = data;
                setSessionStorage(data);
                form.setState(Object.assign({}, form.state, {formData: data}), callback);
            }, () => {
                let data = Object.assign({}, form.state.formData);
                data.running = false;
                form.setState(Object.assign({}, form.state, {formData: data}));
            });
        });
    } else if (form && (!b || !equalFormData(formData))) {
        let data = Object.assign({}, formData, {running: false, result: []});
        window.formData = data;
        setSessionStorage(data);
        form.setState(Object.assign({}, form.state, {formData: data}), callback);
    } else {
        window.formData = formData;
        setSessionStorage(formData);
        if (callback)
            callback()
    }
};
const setSessionStorage = (data) => {
    if (window.setSessionStorage)
        data = window.setSessionStorage(data)

    try {
        sessionStorage.setItem(window.formDataId, JSON.stringify(data));
    } catch (error) {
        sessionStorage.clear()
    }
}
const getSconto = (v) => {
    let item = v.sconto_item || {};
    if (item.type === "%")
        return (item.sconto_percentuale || 0) / 100 * (v.imponibile_pre_sconto || 0)
    if (v.imponibile_pre_sconto !== undefined)
        return Math.min(v.imponibile_pre_sconto, item.sconto || 0)
    return item.sconto || 0;
}
const validateFormData = (formData) => {
    formData = _.cloneDeep(formData);
    if (window.validateFormData) {
        formData = window.validateFormData(formData)
    } else {
        if (formData.order) {
            let prices = window.packages[formData.order.tipologia_abbonamento];
            if ((formData.order.numero_sedi || 0) >= 1) {
                let year_prices = prices[formData.order.numero_sedi],
                    devices_x_unit = prices.devices_x_unit || formData.order.devices_x_unit;
                if (!year_prices)
                    year_prices = prices[_.max(Object.keys(prices))]
                formData.order.licence_devices = devices_x_unit * formData.order.numero_sedi
                prices = year_prices.map((v, y) => ({
                    'year': y + 1,
                    'prezzo': v
                }))
            } else {
                formData.order.licence_devices = 0
                prices = undefined
            }
            formData.order.prices = prices
        }
        if (formData.offerte) {
            formData.offerte = formData.offerte.map(d => {
                if (d.cliente.hasOwnProperty('cassa_previdenziale')) {
                    if (d.cliente.hasOwnProperty('cassa_previdenziale_altro')) {
                        d.cliente.cassa_previdenziale = d.cliente.cassa_previdenziale_altro
                    } else if (d.cliente.hasOwnProperty('cassa_previdenziale_professionista')) {
                        d.cliente.cassa_previdenziale = d.cliente.cassa_previdenziale_professionista
                    } else if (d.cliente.tipologia_cliente === "Professionista / Artista") {
                        if (!window.schema.definitions.cassa_previdenziale_professionista.enum.includes(d.cliente.cassa_previdenziale)) {
                            d.cliente.cassa_previdenziale = "INPS gestione separata senza altra cassa"
                        }
                        d.cliente.cassa_previdenziale_professionista = d.cliente.cassa_previdenziale
                    } else {
                        if (!window.schema.definitions.cassa_previdenziale_altro.enum.includes(d.cliente.cassa_previdenziale)) {
                            d.cliente.cassa_previdenziale = "INPS gestione separata senza altra cassa"
                        }
                        d.cliente.cassa_previdenziale_altro = d.cliente.cassa_previdenziale
                    }
                }
                (d.oggetto.beni_servizi || []).forEach(v => {
                    if (v.hasOwnProperty('sconto')) {
                        v.sconto_item = {type: "€", sconto: v.sconto}
                        delete v['sconto']
                    } else if (v.hasOwnProperty('sconto_percentuale')) {
                        v.sconto_item = {
                            type: "%",
                            sconto_percentuale: v.sconto_percentuale
                        }
                        delete v['sconto_percentuale']
                    }
                    let item = v.sconto_item || {};
                    if (item.type === "%") {
                        item.sconto = (item.sconto_percentuale || 0) / 100 * (v.imponibile_pre_sconto || 0)
                        item.sconto = Math.round((item.sconto * 100 + Number.EPSILON)) / 100
                    } else if (item.type === "€") {
                        item.sconto_percentuale = v.imponibile_pre_sconto ? (item.sconto || 0) * 100 / v.imponibile_pre_sconto : 0;
                        item.sconto_percentuale = Math.round((item.sconto_percentuale * 100 + Number.EPSILON)) / 100
                    }
                    if (Number(item.sconto) > Number(v.imponibile_pre_sconto)) {
                        item.sconto_percentuale = 100
                        item.sconto = (item.sconto === v.imponibile_pre_sconto + 0.001) ? 0 : 0.001
                        item.sconto += v.imponibile_pre_sconto
                    }
                })
                d.oggetto.tco = d.oggetto.tco || [];
                let k = 'Pneumatici';
                d.oggetto.tco.sort((x, y) => (x.label === k ? -1 : y.label === k ? 1 : 0));
                if (!d.oggetto.tco.length || d.oggetto.tco[0].label !== k)
                    d.oggetto.tco.unshift({label: k});
                let fin = d.finanziamento;
                if (['rateale', 'leasing', 'rata-mia'].includes(fin.modalita_pagamento)) {
                    if (fin.modalita_pagamento === 'rata-mia') {
                        fin.modalita = [
                            {
                                modalita_pagamento: 'rateale'
                            },
                            {
                                modalita_pagamento: 'leasing'
                            }
                        ]
                    }
                    let bsf = {},
                        iva_finanziabile = fin.hasOwnProperty('iva_finanziabile') ? fin.iva_finanziabile : true,
                        p_anticipo = 1 - (fin.percentuale_finanziabile_anticipo || 0) / 100,
                        p_residuo = (fin.percentuale_finanziato_residuo || 0) / 100;
                    ;
                    (fin.beni_servizi_finanziabilita || []).forEach(d => {
                        if (d.label) bsf[d.label] = d
                    });
                    fin.beni_servizi_finanziabilita = d.oggetto.beni_servizi.map(d => {
                        let imponibile = (d.imponibile_pre_sconto || 0) - getSconto(d),
                            r = {}, prev = bsf[d.label] || {};
                        r.label = d.label;
                        if (prev.hasOwnProperty('finanziabilita')) {
                            r.finanziabilita = prev.finanziabilita
                        } else {
                            r.finanziabilita = ![
                                "Contributo governativo",
                                "Contributo commerciale",
                                "Sconto di cassa / arrotondamento"
                            ].includes(r.label)
                        }
                        let iva = imponibile * ({
                            'Ordinaria': 0.22,
                            'Ridotta': 0.04
                        }[d.tipo_aliquota_iva] || 0);
                        r.prezzo_scontato = imponibile + iva;
                        if (r.finanziabilita) {
                            let finanziabile = iva_finanziabile ? r.prezzo_scontato : imponibile,
                                finanziato = finanziabile;
                            r.finanziabile = Math.round(finanziabile * 100 + Number.EPSILON) / 100;
                            if (!isNaN(prev.anticipo_cash)) {
                                r.anticipo_cash = prev.anticipo_cash;
                                if (iva_finanziabile) {
                                    if (r.anticipo_cash > finanziabile)
                                        r.anticipo_cash = finanziabile;
                                    finanziato -= r.anticipo_cash;
                                } else {
                                    if (r.anticipo_cash < iva) {
                                        r.anticipo_cash = iva;
                                    } else if (r.anticipo_cash > finanziabile + iva)
                                        r.anticipo_cash = finanziabile + iva;
                                    finanziato -= r.anticipo_cash - iva;
                                }
                            } else {
                                finanziato *= p_anticipo
                            }
                            r.finanziato = finanziato;
                            if (!isNaN(prev.residuo)) {
                                r.residuo = prev.residuo;
                                if (r.residuo > finanziato)
                                    r.residuo = finanziato
                            } else if ([
                                "Servizi",
                                "Kit Sicurezza",
                                "Spese contrattuali / gestione pratica",
                                "Spese varie e servizi \"occulti\"",
                                "Spese di incasso totali",
                                "Bolli contrattuali"
                            ].includes(r.label)) {
                                r.residuo = 0
                            }
                        }
                        r.anticipo_calcolato = Math.round((((r.prezzo_scontato || 0) - (r.finanziato || 0)) || 0) * 100 + Number.EPSILON) / 100
                        if (!(r.finanziabilita && (fin.modalita_pagamento === 'rata-mia' || fin.rata_mia) && isNaN(r.residuo)))
                            r.residuo_calcolato = Math.round(((r.finanziabilita && (!isNaN(r.residuo) ? r.residuo : ((r.finanziato || 0) * p_residuo))) || 0) * 100 + Number.EPSILON) / 100
                        return r
                    });

                    fin.tot_anticipo_calcolato = Math.round(fin.beni_servizi_finanziabilita.reduce((r, v) => (r + (((v.prezzo_scontato || 0) - (v.finanziato || 0)) || 0)), 0) * 100 + Number.EPSILON) / 100

                    if (fin.modalita_pagamento !== 'rata-mia' && !fin.rata_mia) {
                        fin.tot_residuo_calcolato = Math.round(fin.beni_servizi_finanziabilita.reduce((r, v) => (r + ((v.finanziabilita && (!isNaN(v.residuo) ? v.residuo : ((v.finanziato || 0) * p_residuo))) || 0)), 0) * 100 + Number.EPSILON) / 100
                        fin.valore_di_vendita_teorico_calcolato = Math.round(fin.beni_servizi_finanziabilita.filter(
                            v => (["Veicolo", "Messa in strada", "Optionals", "Allestimenti", "Accessori", "Bolli", "IPT"].includes(v.label))).reduce(
                            (r, v) => (r + (v.residuo || v.residuo === 0 ? v.residuo : ((v.prezzo_scontato || 0) * p_residuo))), 0
                        ) * 100) / 100
                        if (['rateale', 'rata-mia'].includes(fin.modalita_pagamento) && fin.fine_contratto === 'lascio' && fin.use_valore_di_vendita_teorico_calcolato)
                            fin.valore_di_vendita_teorico = fin.valore_di_vendita_teorico_calcolato
                    } else {
                        fin.valore_di_vendita_teorico_calcolato = "n.d."
                        if (fin.use_valore_di_vendita_teorico_calcolato)
                            delete fin.valore_di_vendita_teorico
                    }
                } else {
                    delete fin.valore_di_vendita_teorico_calcolato
                }
                return d
            });
        } else if (formData.hasOwnProperty('offerte')) {
            delete formData.offerte
        }
        if (formData.result) {
            formData.result = formData.result.map(d => {
                let dettagli = d.dettagli || {};
                [d, dettagli].forEach((v, i) => {
                    if (!isNaN(v.costo_1000km))
                        v.costo_km = (Math.round((v.costo_1000km / 10 + Number.EPSILON)) / 100).toLocaleString('it');

                    if (!isNaN(v.periodicita_pagamenti))
                        v.periodicita_pagamenti_label = {
                            1: window.lang('$Mensile'),
                            2: window.lang('$Bimestrale'),
                            3: window.lang('$Trimestrale'),
                            4: window.lang('$Quadrimestrale'),
                            12: window.lang('$Annuale')
                        }[v.periodicita_pagamenti];

                    if (!isNaN(v.totale_pagato_bene) || !isNaN(v.maxi_rata_bene))
                        v.totale_pagato_bene_netto_maxi = (v.totale_pagato_bene || 0) - (v.maxi_rata_bene);
                    if (!isNaN(v.totale_pagato_sconto) || !isNaN(v.maxi_rata_sconto))
                        v.totale_pagato_sconto_netto_maxi = (v.totale_pagato_sconto || 0) - (v.maxi_rata_sconto);
                    if (!isNaN(v.totale_pagato_servizio) || !isNaN(v.maxi_rata_servizio))
                        v.totale_pagato_servizio_netto_maxi = (v.totale_pagato_servizio || 0) - (v.maxi_rata_servizio);
                    if (!isNaN(v.totale_pagato_tco) || !isNaN(v.maxi_rata_tco))
                        v.totale_pagato_tco_netto_maxi = (v.totale_pagato_tco || 0) - (v.maxi_rata_tco);
                    if (!isNaN(v.totale_pagato_permuta_bene) || !isNaN(v.maxi_rata_permuta_bene))
                        v.totale_pagato_permuta_bene_netto_maxi = (v.totale_pagato_permuta_bene || 0) - (v.maxi_rata_permuta_bene);
                    if (!isNaN(v.totale_pagato_permuta_servizio) || !isNaN(v.maxi_rata_permuta_servizio))
                        v.totale_pagato_permuta_servizio_netto_maxi = (v.totale_pagato_permuta_servizio || 0) - (v.maxi_rata_permuta_servizio);
                    let vv = [v.totale_pagato_vendita_bene, v.maxi_rata_bene, v.maxi_rata_sconto, v.maxi_rata_permuta_bene].filter(e => (!isNaN(e)));
                    if (vv.length)
                        v.totale_pagato_maxi_bene = vv.reduce((r, e) => (r + e), 0);
                    vv = [v.totale_pagato_vendita_servizio, v.maxi_rata_servizio, v.maxi_rata_tco, v.maxi_rata_permuta_servizio].filter(e => (!isNaN(e)));
                    if (vv.length)
                        v.totale_pagato_maxi_servizio = vv.reduce((r, e) => (r + e), 0);
                });
                return d
            });
        } else if (formData.hasOwnProperty('result')) {
            delete formData.result
        }
    }
    return formData
};
const safeJSONparse = (text) => {
    try {
        return JSON.parse(text.replace(/\bNaN\b/g, "null").replace(/\bInfinity\b/g, "1e+9999"))
    } catch (e) {
        return {}
    }
}

function parseStoredJWT() {
    let jwt = localStorage.getItem(window.formDataId.replace('formData', 'jwt'));
    try {
        jwt = jwt ? safeJSONparse(jwt) : null;
    } catch (error) {
        console.error(error);
        jwt = null;
    }
    return jwt
}

async function getJWT() {
    let jwt = parseStoredJWT();
    if (!jwt || !jwt.access_token || (new Date().getTime() / 1000) > jwt.expire) {
        let url = '/api/login';
        jwt = await secureFetch(url, {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (jwt)
            localStorage.setItem(window.formDataId.replace('formData', 'jwt'), JSON.stringify(jwt));
    }
    return (jwt || {}).access_token
}


window.lang = (k) => (_.get(window.dictionary, k, k));

function secureFetch(url, init, callback, errorCallback) {
    let raise = true;
    return fetch(url, Object.assign({
        crossDomain: true,
        method: 'GET'
    }, init || {})).then(response => {
        if (response.redirected) {
            window.location.href = response.url
        } else if (response.status !== 200) {
            if (response.status === 401 && (window.formDataId || '').startsWith('prosa-formData')) {
                localStorage.removeItem(window.formDataId.replace('formData', 'jwt'));
            }
            response.text().then(res => {
                return safeJSONparse(res)
            }).then(err => {
                alert(window.lang("$Error:") + response.status + '\n\n' + window.lang(err.msg))
            })
            raise = false
            throw new Error(response.status)
        }
        return response.text()
    }).then(res => {
        return safeJSONparse(res)
    }).then(callback || (r => r)).catch(function (error) {
        if (errorCallback)
            errorCallback(error)
        if (raise)
            alert(error)
    })
}

function fetchAPI(access_token, url, init, callback, errorCallback) {
    if (access_token)
        return getJWT().then(jwt => {
            init = _.merge(init || {}, {headers: {'Authorization': 'Bearer ' + jwt}})
            secureFetch(url, Object.assign({}, init,), callback, errorCallback)
        })
    return secureFetch(url, init, callback, errorCallback)
}

window.fetchAPI = fetchAPI;

function renderForm(schema, uiSchema, formData) {
    ReactDOM.render((
        <Form schema={schema}
              uiSchema={uiSchema}
              fields={fields}
              widgets={widgets}
              formData={formData}
              onChange={onChange}
              onSubmit={log("submitted")}
              transformErrors={transformErrors}
              liveValidate={true}
              formContext={{getForm}}
              omitExtraData={true}
              liveOmit={true}
              ref={_form => {
                  form = _form;
                  window.form = _form;
              }}
              className="main-form"
              children={<br/>}
              ArrayFieldTemplate={ArrayFieldTemplate}
        />
    ), document.getElementById("app"));
}

let getDeviceId = async () => {
    const fpAgent = await FingerprintJS.load();
    const result = await fpAgent.get();
    let visitorId = result.visitorId;
    if (!visitorId) {
        visitorId = localStorage.get('prosa-device-id');
        if (!visitorId) {
            visitorId = [...Array(32)].map(i => (~~(Math.random() * 36)).toString(36)).join('')
            localStorage.setItem('prosa-device-id', visitorId)
        }
    }
    return visitorId
}

(async () => {
    if (window.sendDeviceId)
        await getDeviceId().then(visitorId => {
            fetchAPI(false, window.sendDeviceId, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({'device_id': visitorId})
            })
        });

    if (window.formDataId) {
        function skipArray(objValue, srcValue) {
            if (_.isArray(srcValue)) {
                return srcValue
            }
        }

        let item = sessionStorage.getItem(window.formDataId),
            formData = item ? safeJSONparse(item) : {},
            temp = sessionStorage.getItem('temp-prosa-func');
        temp = temp ? safeJSONparse(temp) : {}
        if (Object.keys(temp).length) {
            sessionStorage.removeItem('temp-prosa-func')
            formData = eval('(' + temp.func + ')')(formData, temp.data);
        }
        window.options = window.options || {};
        if (window.promises)
            await Promise.all(window.promises.map(f => f()))
        window.formData = validateFormData(_.mergeWith(formData, window.formData, skipArray));
        setSessionStorage(window.formData);
        window.history.replaceState({}, null, window.location.pathname);
        renderForm(window.schema, window.uiSchema, window.formData)
    } else if (window.setDeviceId) {
        document.getElementById(window.setDeviceId).value = await getDeviceId();
    }
})();