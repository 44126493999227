import FileWidget from '@rjsf/core/dist/cjs/components/widgets/FileWidget'
import './dnd.less'
import React from 'react';
import Dropzone from 'react-dropzone';

export default class ImageUploadWidget extends FileWidget {
    onDrop(files) {
        this.onChange({target: {files: files}});
    }

    render() {
        let key = this.props.id;
        const files = this.state.values.filter(file => file !== undefined).map((file, i) => {
                return <img
                    key={key + '-' + i} src={file} alt={window.lang('$spiacente')}
                    width={300}/>
            }
        );

        return this.props.readonly ? files : (
            <Dropzone key={key + '-dropzone'} onDrop={this.onDrop.bind(this)}>
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        {!files.length ?
                            <p>{window.lang('$image-text')}</p> : null}
                        <div>{files}</div>
                    </div>
                )}
            </Dropzone>
        );
    }
}
