import React from 'react';
import ReactDOM from "react-dom"
import {
    Columns,
    Card,
    Section,
    Dropdown,
    Button,
    Modal,
    Navbar
} from "react-bulma-components";
import ObjectField from '@rjsf/core/lib/components/fields/ObjectField'
import {retrieveSchema, toIdSchema} from "@rjsf/core/lib/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown"
import {
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faArrowRight,
    faArrowLeft,
    faFlag,
    faShoePrints,
    faCar,
    faPlay,
    faWallet,
    faBalanceScaleLeft,
    faInfoCircle,
    faCogs,
    faCog,
    faSave,
    faUpload,
    faFileDownload,
    faUser,
    faCoins,
    faEuroSign,
    faSignOutAlt,
    faCloudDownloadAlt,
    faCloudUploadAlt,
    faCloud,
    faEdit,
    faFolderPlus,
    faClone,
    faRocket,
    faPlusCircle,
    faPlus,
    faHandPointer,
    faCertificate,
    faPrint,
    faSignInAlt,
    faEllipsisH,
    faFilePdf,
    faPaste
} from "@fortawesome/free-solid-svg-icons";
import {faCreativeCommonsShare} from "@fortawesome/free-brands-svg-icons"
import {
    ReflexContainer, ReflexSplitter, ReflexElement, ReflexHandle
} from 'react-reflex'
import Form from "@rjsf/core/lib";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {Layout, Model} from "flexlayout-react";
import 'flexlayout-react/style/light.css'
import 'Flex.css'
import isEqual from "lodash/isEqual";

const icons = {
    "paste": faPaste,
    "pdf": faFilePdf,
    "print": faPrint,
    "creative-commons-share": faCreativeCommonsShare,
    "certificate": faCertificate,
    "hand-pointer": faHandPointer,
    "user": faUser,
    "plus": faPlus,
    "plus-circle": faPlusCircle,
    "clone": faClone,
    "euro": faEuroSign,
    "flag": faFlag,
    "car": faCar,
    "rocket": faRocket,
    "play": faPlay,
    "coins": faCoins,
    "shoe-prints": faShoePrints,
    "wallet": faWallet,
    "balance-scale-left": faBalanceScaleLeft,
    "info-circle": faInfoCircle,
    "chevron-up": faChevronUp,
    "chevron-down": faChevronDown,
    "chevron-left": faChevronLeft,
    "chevron-right": faChevronRight,
    "cogs": faCogs,
    "edit": faEdit,
    "cog": faCog,
    "save": faSave,
    "upload": faUpload,
    "download": faFileDownload,
    "logout": faSignOutAlt,
    "login": faSignInAlt,
    "cloud-upload": faCloudUploadAlt,
    "cloud-download": faCloudDownloadAlt,
    "cloud": faCloud,
    "folder-plus": faFolderPlus,
    "ellipsis-h": faEllipsisH
};

function getTitle(name, parent) {
    const {rootSchema} = parent.props.registry;
    const properties = retrieveSchema(parent.props.schema, rootSchema).properties;
    return (properties[name] !== undefined && retrieveSchema(properties[name], rootSchema).title) || name;
}

function getContent(content, parent, id, extra) {
    if (typeof content === 'object')
        content = element(content, parent, id, extra) || content;
    return content
}

class ControlledElement extends React.Component {
    constructor(props) {
        super(props);
        let size = props.size || -1;
        this.onLockSizeClicked = this.onLockSizeClicked.bind(this);
        this.onMinimizeClicked = this.onMinimizeClicked.bind(this);
        this.onMaximizeClicked = this.onMaximizeClicked.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.state = {size: size, open: props.open, status: null}
    }

    componentDidMount() {
        if (this.state.open === true) {
            this.onMaximizeClicked()
        } else if (this.state.open === false) {
            this.onMinimizeClicked()
        }
    }

    onLockSizeClicked() {
        this.props.onLockSize({
            locked: this.props.sizeLocked,
            paneId: this.props.id,
            size: this.getSize()
        })
    }

    onMinimizeClicked(event) {
        if (event)
            event.stopPropagation();
        const currentSize = this.getSize();
        const minSize = this.props.minSize || 0;
        const update = (size) => {
            return new Promise((resolve) => {
                this.setState(Object.assign({},
                    this.state, {
                        size: size < minSize ? minSize : size,
                        open: null, status: false
                    }), () => resolve())
            })
        };
        const done = (from, to) => {
            return from < to
        };
        this.animate(currentSize, minSize, minSize - currentSize, done, update)
    }

    onMaximizeClicked(event) {
        if (event)
            event.stopPropagation();
        const currentSize = this.getSize();
        const maxSize = this.props.maxSize || Infinity;
        const update = (size) => {
            return new Promise((resolve) => {
                this.setState(Object.assign({},
                    this.state, {
                        size, open: null, status: true
                    }), () => resolve())
            })
        };
        const done = (from, to) => {
            return from > to
        };
        this.animate(currentSize, maxSize, maxSize - currentSize, done, update)
    }

    onDoubleClick(event) {
        if (event)
            event.stopPropagation();
        if (this.state.status) {
            this.onMinimizeClicked(event)
        } else {
            this.onMaximizeClicked(event)
        }
    }

    getSize() {
        const domElement = ReactDOM.findDOMNode(this);
        switch (this.props.orientation) {
            case 'horizontal':
                return domElement.offsetHeight;
            case 'vertical':
                return domElement.offsetWidth;
            default:
                return 0
        }
    }

    animate(from, to, step, done, fn) {
        const stepFn = () => {
            if (!done(from, to)) {
                fn(from += step).then(() => {
                    setTimeout(stepFn, 8)
                })
            }
        };
        stepFn()
    }

    render() {
        const {size, key, header = {}, ...props} = this.props;
        return (
            <ReflexElement key={key} size={this.state.size} {...props}>
                <ReflexHandle className={"handle"}>
                    <div
                        onDoubleClick={this.onDoubleClick}
                        key={key + '-header'}
                        className="level is-mobile card-header-title"
                        {...header}>
                        <div className="level-item" key={key + '-header-title'}>
                            {getContent(this.props.title, this.props.parent, key + '-header-title-content')}
                        </div>
                    </div>
                </ReflexHandle>
                {this.props.children}
            </ReflexElement>
        )
    }
}

class ControlledCarousel extends React.Component {
    render() {
        let key = this.props.id + '-carousel', data = this.props.data || {};
        return <Carousel key={key} {...data.props} >
            {this.props.children.map((c, i) => (
                <div key={i}>
                    {c}
                </div>
            ))}
        </Carousel>
    }
}

function Heading(props) {
    const level = props.level;
    const tagName = `h${level}`;
    return React.createElement(
        tagName, {className: `title is-${level}`},
        props.children
    );
}

function compileFunc(func) {
    if (typeof func === 'string')
        return new Function("return " + func)()
    return func
}

export function element(data, parent, id, extra) {
    extra = extra || {};
    if (data.transformData)
        data = compileFunc(data.transformData)(data, parent, id, extra);
    let props = data.props || {}, type = data.type, content = data.content;
    if (data.domain && !compileFunc(data.domain)(data, parent, id, extra))
        return null;
    if (content) {
        if (data.args)
            content = content.format2(compileFunc(data.args)(data, parent, id, extra))
        if (type === 'markdown') {
            content = <div key={id} className='markdown' {...props}>
                <ReactMarkdown renderers={{heading: Heading}}>
                    {content}
                </ReactMarkdown>
            </div>
        } else if (typeof content === 'string' || content instanceof String) {
            content = content.split('\n').map((v, i) => (
                [i !== 0 ? <br key={i}/> : null, v]
            ))
        }
    } else if (type === 'parent') {
        return element(data.element, parent.props.parent, id + '-parent', extra)
    } else if (type === 'dropdown') {
        content = data.children.map((d, i) => {
            return element(d, parent, id + '-' + i, extra)
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        content = <div key={id} className="dropdown" {...data.props}>
            <div className="dropdown-trigger" key={id + '-title'}>
                <div aria-haspopup="true" aria-controls={id}>
                    {element(data.title, parent, id + '-title-content', extra)}
                </div>
            </div>
            <div className="dropdown-menu is-paddingless" id={id} role="menu"
                 key={id + '-menu'} {...data.menu}>
                <div
                    className="dropdown-content" {...data['dropdown-content']}>{content}</div>
            </div>
        </div>;
    } else if (type === 'nav') {
        content = data.children.map((d, i) => {
            return element(d, parent, id + '-' + i, extra)
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        content = <Navbar key={id} {...props}>
            <Navbar.Brand key={id + '-brand'}>
                {element(data.brand, parent, id + '-brand-content', extra)}
                <Navbar.Burger
                    className={parent.state[id] ? "is-active" : null}
                    data-target={id + '-menu'} onClick={() => {
                    let state = Object.assign({}, parent.state);
                    state[id] = !state[id];
                    parent.setState(state);
                }}/>
            </Navbar.Brand>
            <Navbar.Menu id={id + '-menu'} key={id + '-menu'}
                         className={parent.state[id] ? "is-active" : null}>
                {content}
            </Navbar.Menu>
        </Navbar>
    } else if (type === 'button') {
        let onClick;
        content = (data.children || []).map((d, i) => {
            return element(d, parent, id + '-' + i, extra)
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        if (data.onClick)
            onClick = compileFunc(data.onClick).bind(null, data, parent, id, extra);
        return <Button renderAs='div' key={id} onClick={onClick} {...props}>
            {content}
        </Button>
    } else if (type === 'checkbox') {
        content = (data.children || []).map((d, i) => {
            return element(d, parent, id + '-' + i, extra)
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        let onChange = parent.props.onChange, isChecked;
        if (data.onChange)
            onChange = compileFunc(data.onChange).bind(null, data, parent, id, extra);
        if (data.isChecked)
            isChecked = compileFunc(data.isChecked)(data, parent, id, extra);
        return <div key={id}>
            <input
                type="checkbox"
                className='is-checkradio is-circle'
                id={id}
                key={id + '-input'}
                name={data.name || id}
                checked={isChecked}
                onChange={event => onChange(event.target.checked)}
            /><label key={id + '-label'} htmlFor={id}>{content}</label>
        </div>
    } else if (type === 'radio') {
        content = (data.children || []).map((d, i) => {
            return element(d, parent, id + '-' + i, extra)
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        let onChange = parent.props.onChange, isChecked = parent.props.formData;
        if (data.onChange)
            onChange = compileFunc(data.onChange).bind(null, data, parent, id, extra);
        if (data.isChecked)
            isChecked = compileFunc(data.isChecked)(data, parent, id, extra);
        return <div key={id}>
            <input
                type="radio"
                className='is-checkradio is-circle'
                id={id}
                key={id + '-input'}
                name={data.name || id}
                checked={isChecked}
                onChange={event => onChange(event.target.value)}
            /><label key={id + '-label'} htmlFor={id}>{content}</label>
        </div>
    } else if (type === 'icon') {
        let onClick;
        if (data.onClick)
            onClick = compileFunc(data.onClick).bind(null, data, parent, id, extra);
        return <FontAwesomeIcon
            key={id} onClick={onClick} icon={icons[data.icon]} {...props}/>
    } else if (type === 'table') {
        let {formData = {}} = parent.props;
        if (data.formData)
            formData = data.formData;

        const {rootSchema} = parent.props.registry;
        let schema = retrieveSchema(parent.props.schema, rootSchema).properties,
            items = data.schemaItems;
        content = formData[data.id];
        if (data.transformContent)
            content = compileFunc(data.transformContent)(content, formData, data, parent, id, extra);
        if (!items && schema[data.id])
            items = retrieveSchema(schema[data.id], rootSchema).items.properties;
        if (items && content) {
            let width = 1 / data.columns.length * 100 + '%',
                columns = new Set(), title = data.title;
            content.forEach(v => {
                Object.keys(v).forEach(k => {
                    columns.add(k)
                })
            });
            columns = data.columns.filter(d => columns.has(d.id));
            if (!data.hasOwnProperty('header'))
                data.header = true;
            if (!data.hasOwnProperty('footer'))
                data.footer = true;
            if (title)
                title = (data.titlePrefix || "") + title + (data.titleSuffix || "");
            content = <div key={id}>
                {title ?
                    <div key={id + '-title'}
                         className="has-text-centered has-text-weight-bold" {...data.propsTitle}>{title}</div> : null}
                <div key={id + '-wrap'} className="table-container table-wrap">
                    <table key={id + '-table'}
                           className="table is-striped is-narrow is-fullwidth is-marginless" {...props}>
                        {data.header ? <thead key={id + '-head'}>
                        <tr key={id + '-head-row'}>
                            {columns.map((d, i) => {
                                return <th
                                    width={(!d.hasOwnProperty('width') && width) || d.width || 0}
                                    key={id + '-head-row-' + (i + 1)}>
                                    {d.hasOwnProperty('title') ? d.title : retrieveSchema(items[d.id], rootSchema).title}
                                    {d.titleInfo ? element(d.titleInfo, parent, id + '-head-row-' + (i + 1) + '-info', extra) : null}
                                </th>
                            })}
                        </tr>
                        </thead> : null}
                        <tbody key={id + '-body'}>
                        {content.map((v, i) => {
                            let key = id + '-row-' + i;
                            return <tr key={key}>
                                {columns.map((d, j) => {
                                    let val = v[d.id];
                                    if (d.format)
                                        val = compileFunc(d.format)(val);
                                    if (typeof val == 'number')
                                        val = Math.round(val).toLocaleString('it');
                                    return <td
                                        key={key + '-' + j} {...d.propsTd}>
                                        {(d.valuePrefix || "") + val + (d.valueSuffix || "")}
                                    </td>
                                })}
                            </tr>
                        })}
                        </tbody>
                        {data.footer ? <tfoot>
                        <tr key={id + '-foot-row'}>
                            {columns.map((d, i) => {
                                let footer = d.footer;
                                if (d.footerCalculate) {
                                    footer = compileFunc(d.footerCalculate)(content);
                                }
                                return <th
                                    width={(!d.hasOwnProperty('width') && width) || d.width || 0}
                                    key={id + '-footer-row-' + (i + 1)}>
                                    {footer}
                                </th>
                            })}
                        </tr>
                        </tfoot> : null}
                    </table>
                </div>
            </div>
        }
    } else if (type === 'main') {
        const {rootSchema, fields, formContext} = parent.props.registry;
        const mainForm = formContext.getForm();
        const {SchemaField} = fields;
        return <SchemaField
            key={id}
            id={id}
            schema={rootSchema}
            uiSchema={data.uiSchema}
            onChange={(data) => {
                mainForm.setState({formData: data}, () => {
                    parent.setState(parent.state)
                })
            }}
            formData={mainForm.state.formData}
            registry={parent.props.registry}/>
    } else if (type === 'modal-card') {
        let onOpen = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (data.onOpen) {
                compileFunc(data.onOpen)(parent, id, data, extra)
            } else {
                let state = Object.assign({}, parent.state);
                state[id] = true;
                parent.setState(state);
            }
        }, onClose = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let state = Object.assign({}, parent.state);
            state[id] = false;
            parent.setState(state);
            if (data.onCloseCallback)
                compileFunc(data.onCloseCallback)(parent, state, id, data, extra);
        };
        return <div key={id} {...data.props}>
            {data.button ?
                <div key={id + '-button'}
                     className="button is-rounded" {...data.button}
                     onClick={onOpen}>
                    <p>
                        {data.icon ? <span key={id + '-icon'}>
                        <FontAwesomeIcon icon={icons[data.icon]}/>
                    </span> : null}
                        {data.icon ? " " : null}
                        <span key={id + '-title'}>
                        {getContent(data.title) || getTitle(data.element.id, parent)}
                    </span>
                    </p>
                </div> :
                <FontAwesomeIcon
                    key={id + '-button'} icon={icons[data.icon]}
                    onClick={onOpen}/>}
            <Modal key={id + '-modal'} show={!!parent.state[id]}
                   onClose={onClose} {...data.modal} closeOnBlur>
                <Modal.Card key={id + '-card'}  {...data.modalCard}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}>
                    <Modal.Card.Head onClose={onClose}
                                     key={id + '-card-head'} {...data.modalCardHead}>
                        <Modal.Card.Title key={id + '-card-head-title'}>
                            {data.cardTitle ? element(data.cardTitle, parent, id + '-card-head-title-content', extra) : null}
                        </Modal.Card.Title>
                    </Modal.Card.Head>
                    <Modal.Card.Body
                        key={id + '-card-body'} {...data.modalCardBody}>
                        {data.body ? element(data.body, parent, id + '-card-body-content', extra) : null}
                    </Modal.Card.Body>
                    {data.foot ? <Modal.Card.Foot
                        key={id + '-card-foot'} {...data.modalCardFoot}>
                        {element(data.foot, parent, id + '-card-foot-content', extra)}
                    </Modal.Card.Foot> : null}
                </Modal.Card>
            </Modal>
        </div>
    } else if (type === 'modal') {
        let onOpen = () => {
            if (data.onOpen) {
                compileFunc(data.onOpen)(parent, id)
            } else {
                let state = Object.assign({}, parent.state);
                state[id] = true;
                parent.setState(state);
            }
        }, onClose = () => {
            let state = Object.assign({}, parent.state);
            state[id] = false;
            parent.setState(state);
        };
        return <div key={id} {...data.props}>
            <Button
                renderAs='div'
                key={id + '-button'}
                className="is-rounded" {...data.button}
                onClick={onOpen}>
                <p>
                    {data.icon ? <span key={id + '-icon'}>
                        <FontAwesomeIcon icon={icons[data.icon]}/>
                    </span> : null}
                    {data.icon ? " " : null}
                    <span key={id + '-title'}>
                        {data.title || getTitle(data.element.id, parent)}
                    </span>
                </p>
            </Button>
            <Modal key={id + '-modal'} show={!!parent.state[id]}
                   onClose={onClose} {...data.modal} closeOnBlur>
                <Modal.Content key={id + '-content'} {...data.modalContent}>
                    {element(data.element, parent, id + '-modal-content', extra)}
                </Modal.Content>
            </Modal>
        </div>
    } else if (type === 'reflex') {
        content = [];
        let open = parent.state.open;
        data.children.forEach((d, i) => {
            if (!d.domain || compileFunc(d.domain)(parent, i)) {
                let {size, ...kw} = (d.props || {}), key = id + '-element-' + i;
                if (i && d.splitter !== false)
                    content.push(<ReflexSplitter
                        key={id + '-splitter-' + i} {...d.splitter}>
                        {d.splitter_element && element(d.splitter_element, parent, key + '-splitter-element-' + i)}
                    </ReflexSplitter>);
                if (open && open.id === i) {
                    kw.open = open.status
                }
                let Component = d.handle ? ControlledElement : ReflexElement;
                content.push(<Component
                    key={key} id={key} size={size} {...kw}
                    parent={parent}
                    onStartResize={() => {
                        if (parent.state.open) {
                            parent.setState(Object.assign({}, parent.state, {open: null}))
                        }
                    }}>
                    {element(d.element, parent, key + '-content', extra)}
                </Component>)
            }
        });
        return <ReflexContainer key={id} {...props}>
            {content}
        </ReflexContainer>
    } else if (type === 'flex') {
        const parseModel = (items, element, key = '0') => {
            let {children = null, component = null, domain = null} = element;
            if (!(!domain || compileFunc(domain)(parent, key))) {
                return null
            }
            if (children) {
                children = children.map((ele, index) => parseModel(items, ele, `${key}-${index}`)).filter(ele => ele !== null)
            }
            if (component !== null) {
                return items[component] ? {id: key, ...element} : null
            }
            return children.length ? {id: key, ...element, children} : null
        }
        let {model: rawJSONModel, ...kw} = props;
        const newJSONModel = {
            ...rawJSONModel,
            global: {
                tabEnableRename: false,
                tabSetHeaderHeight: 33,
                tabSetTabStripHeight: 33,
                borderBarSize: 33,
                ...(rawJSONModel.global || {})
            },
            layout: parseModel(data.children, rawJSONModel.layout || {}, `${id}-layout`),
            borders: (rawJSONModel.borders || []).map((ele, index) => parseModel(data.children, ele, `${id}-borders-${index}`)).filter(ele => ele !== null)
        }

        const JSONModel = parent.state.JSONModel || {}
        let model = parent.state.model
        if (!model || !isEqual(JSONModel, newJSONModel)) {
            model = Model.fromJson(newJSONModel)
            parent.setState(Object.assign({}, parent.state, {
                JSONModel: newJSONModel, model
            }))
        }
        const factory = (node) => {
            let i = node.getComponent(),
                key = id + '-element-' + i;
            return element(data.children[i].element, parent, key + '-content', extra)
        };
        return <Layout key={id} model={model} factory={factory} {...kw}/>
    } else if (type === 'steps') {
        let getActive = (data, parent, id) => (parent.state[id] || 0),
            setActive = (data, parent, id, active) => {
                let state = Object.assign({}, parent.state);
                state[id] = active;
                if (data.callback)
                    compileFunc(data.callback)(active, parent, state);
                parent.setState(state);
            };
        if (data.getActive)
            getActive = compileFunc(data.getActive)(data, parent, id, getActive);
        if (data.setActive)
            setActive = compileFunc(data.setActive)(data, parent, id, setActive);

        let active = getActive(data, parent, id),
            steps = data.children.map((d, i) => {
                let key = id + '-step-' + i;
                let marker = i;
                if (d.hasOwnProperty("icon")) {
                    marker = <span className="icon">
                        <FontAwesomeIcon icon={icons[d.icon]}/>
                    </span>
                } else if (d.hasOwnProperty("marker")) {
                    marker = getContent(d.marker, parent, key + '-marker-content')
                }
                return <div
                    key={key}
                    className={"step-item is-clickable" + (i <= active ? " is-completed" : "") + (i === active ? " is-active" : "") + (i === 0 ? " first-step" : "")}
                    onClick={(e) => {
                        e.preventDefault();
                        setActive(data, parent, id, i)
                    }}>
                    <div key={key + '-marker'} className="step-marker">
                        {marker}
                    </div>
                    {(d.title) ? <div className="step-details">
                        <div key={key + '-title'} className="step-title">
                            {getContent(d.title, parent, key + '-title-content')}
                        </div>
                    </div> : null}
                </div>;
            }),
            {
                className,
                ...otherProps
            } = props || {},
            active_element = data.children.filter((d, i) => (i === active)),
            title = active_element.map(d => {
                let key = id + '-step-' + active;
                return getContent(d.detail, parent, key + '-detail-content')
            });
        if (data.children[active].backward) {
            steps = [element(data.children[active].backward, parent, id + '-backward-' + active, extra)].concat(steps)
        } else {
            steps = [<Button
                renderAs='div'
                key={id + '-backward-' + active}
                className="is-rounded is-primary is-small steps-button has-tooltip-arrow has-tooltip-right has-tooltip-bottom"
                disabled={active === 0}
                data-tooltip={window.lang("$Step Precedente")}
                onClick={(e) => {
                    setActive(data, parent, id, active - 1)
                }}><FontAwesomeIcon icon={faArrowLeft}/>
            </Button>].concat(steps)
        }
        if (data.children[active].forward) {
            steps.push(element(data.children[active].forward, parent, id + '-forward-' + active, extra))
        } else {
            steps.push(<Button
                renderAs='div'
                key={id + '-forward-' + active}
                className="is-rounded is-primary is-small steps-button has-tooltip-arrow has-tooltip-left has-tooltip-bottom"
                data-tooltip={window.lang("$Prossimo Step")}
                disabled={active === data.children.length - 1}
                onClick={(e) => {
                    setActive(data, parent, id, active + 1)
                }}>
                <FontAwesomeIcon icon={faArrowRight}/>
            </Button>)
        }
        content = <div key={id} className="vertical-wrapper steps-wrapper">
            <div className="vertical-fix is-bottom-marginless">
                <div
                    key={id + '-menu'}
                    className="level is-mobile array-control-bar card-header-title">
                    <div
                        key={id + '-menu-left'}
                        className="level-left is-flex"{...data.menu_left_props}>{
                        (data.menu_left || []).map((d, i) => {
                            return element(d, parent, id + '-menu-left-' + i, extra)
                        }).filter(e => {
                            return !(e === null || e === undefined)
                        })}{
                        active_element.map(d => ((d.menu_left || []).map((d, i) => {
                            return element(d, parent, id + '-menu-left-' + i, extra)
                        }).filter(e => {
                            return !(e === null || e === undefined)
                        })))}
                    </div>
                    <div key={id + '-menu-title'}
                         className="menu-title centered is-hidden-mobile">{title}</div>
                    <div
                        key={id + '-menu-right'}
                        className="level-right is-flex"{...data.menu_right_props}>{
                        (data.menu_right || []).map((d, i) => {
                            return element(d, parent, id + '-menu-left-' + i, extra)
                        }).filter(e => {
                            return !(e === null || e === undefined)
                        })}{
                        active_element.map(d => ((d.menu_right || []).map((d, i) => {
                            return element(d, parent, id + '-menu-right-' + i, extra)
                        }).filter(e => {
                            return !(e === null || e === undefined)
                        })))}
                    </div>
                </div>
                <div
                    className={['steps', className].join(' ')}
                    key={id + '-steps'}
                    id={id} {...otherProps}>
                    {steps}
                </div>
            </div>
            {data.children.map((d, i) => {
                let key = id + '-step-' + i + '-content';
                return <div key={key}
                            className={["vertical-content", "steps-content", (active === i ? "" : "is-hidden")].concat((d.props || {}).classNames || []).join(' ')}>
                    {element(d.element, parent, key + '-body', extra)}
                </div>
            })}
        </div>
    } else if (type === 'accordion') {
        let getStatus = (parent, key, d, i) => ((!parent.state.hasOwnProperty(key) && (d.open || d.static)) || parent.state[key]),
            setStatus = (parent, key, status, i) => {
                let state = Object.assign({}, parent.state);
                state[key] = status;
                parent.setState(state);
            };
        if (data.getStatus)
            getStatus = compileFunc(data.getStatus)(data, parent, id, getStatus);
        if (data.setStatus)
            setStatus = compileFunc(data.setStatus)(data, parent, id, setStatus);

        content = data.children.map((d, i) => {
            let onClick, header = d.header || {}, body = d.body || {},
                key = id + '-accordion-' + i,
                cnt = element(d.element, parent, key + '-body-content', extra),
                open = getStatus(parent, key, d, i),
                {
                    className,
                    ...otherProps
                } = d.props || {};
            if (!d.static)
                onClick = (e) => {
                    e.preventDefault();
                    setStatus(parent, key, !open, i)
                };
            return cnt ? <Card key={key}
                               className={['accordion', className].join(' ')} {...otherProps}>
                <Card.Header
                    key={key + '-header'}
                    className={"has-background-grey-lighter" + (onClick ? " is-clickable" : "")}
                    onClick={onClick} {...header.props}>
                    <Card.Header.Title
                        key={key + '-header-title'}
                        className="is-block">
                        <div key={key + '-header-title-level'}
                             className="level is-mobile">
                            {(header.number && parent.props.index !== undefined) ? (header.numberPrefix || "") + (parent.props.index + 1) + (header.numberSuffix || "") : null}
                            {getContent(header.title || getTitle(d.id, parent), parent, key + '-header-title-content')}
                            {!d.static ?
                                <div key={key + '-header-title-level-icon'}
                                     className="icon level-right has-tooltip-arrow has-tooltip-bottom has-tooltip-left has-tooltip-arrow"
                                     data-tooltip={window.lang(open ? "$Chiudi" : "$Apri")}>
                                    <FontAwesomeIcon
                                        icon={open ? faChevronUp : faChevronDown}
                                    />&nbsp;
                                </div> : null}
                        </div>
                    </Card.Header.Title>
                </Card.Header>
                {open ?
                    <Card.Content
                        key={key + '-body'}{...body.props}>{cnt}</Card.Content> : null}
            </Card> : null
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        content = content.length ?
            <div key={id} {...props}>{content}</div> : null;
    } else if (type === 'columns') {
        content = data.children.map((d, i) => {
            let key = id + '-column-' + i,
                cnt = element(d.element, parent, key + '-content', extra);
            return cnt ? <Columns.Column
                key={key} {...d.props}>{cnt}</Columns.Column> : null;
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        content = content.length ?
            <Columns key={id} id={id} {...props}>{content}</Columns> : null;
    } else if (type === 'img') {
        content = <img key={id} src={data.src} {...props}/>
    } else if (type === 'value') {
        const {formData = {}} = parent.props;
        if (formData.hasOwnProperty(data.id))
            content = formData[data.id];
        if (data.transformContent)
            content = compileFunc(data.transformContent)(content, formData);
    } else if (type === 'conditional-field') {
        const {formData = {}} = parent.props;
        let e = data.children.filter(d => formData.hasOwnProperty(d.id)), el;
        e = e.length ? e[0] : data.children[0];
        el = element(e, parent, id + '-input', extra);
        content = <div key={id} className="field has-addons">
            <p className="control">
                {el}
            </p>
            <p className="control">
            <span className="select">
                <select onChange={({target: {value}}) => {
                    el.onChange({target: {value: null}})
                }}>
                    {data.children.map(d => {
                        return <option
                            selected={d.id === e.id}>{d.text}</option>
                    })}
                </select>
            </span>
            </p>
        </div>;
        if (formData.hasOwnProperty(data.id)) {
            content = formData[data.id];
            if (data.transformContent)
                content = compileFunc(data.transformContent)(content, formData);
            content = (data.prefix || "") + content + (data.suffix || "");
            let title = data.title || getTitle(data.id, parent);
            if (data.transformTitle)
                title = compileFunc(data.transformTitle)(title, formData);
            title = (data.titlePrefix || "") + title + (data.titleSuffix || "");
            content = <div key={id}
                           className="field is-horizontal" {...data.propsField}>
                <div key={id + '-label'} className="field-label"><label
                    className="label has-text-left-mobile has-text-right-tablet">{title}</label>
                </div>
                <div key={id + '-body'} className="field-body">
                    <div key={id + '-control'}
                         className="control">{content}</div>
                </div>
            </div>
        }
    } else if (type === 'field') {
        const {formData = {}} = parent.props;
        if (formData.hasOwnProperty(data.id)) {
            content = formData[data.id];
            if (data.transformContent)
                content = compileFunc(data.transformContent)(content, formData);
            content = (data.prefix || "") + content + (data.suffix || "");
            let title = data.title || getTitle(data.id, parent);
            if (data.transformTitle)
                title = compileFunc(data.transformTitle)(title, formData);
            title = (data.titlePrefix || "") + title + (data.titleSuffix || "");
            content = <div key={id}
                           className="field is-horizontal" {...data.propsField}>
                <div key={id + '-label'} className="field-label"><label
                    className="label has-text-left-mobile has-text-right-tablet">{title}</label>
                </div>
                <div key={id + '-body'} className="field-body">
                    <div key={id + '-control'}
                         className="control">{content}</div>
                </div>
            </div>
        }
    } else if (type === 'nav-divider') {
        content = (data.children || []).map((d, i) => {
            return element(d, parent, id + '-' + i, extra)
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        content = <Navbar.Divider key={id} {...props}>{content}</Navbar.Divider>
    } else if (type === 'nav-burger') {
        content = (data.children || []).map((d, i) => {
            return element(d, parent, id + '-' + i, extra)
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        content = <Navbar.Burger key={id} {...props}>{content}</Navbar.Burger>
    } else if (type === 'form') {
        const {widgets, fields} = parent.props.registry;
        let children = <div/>;
        if (data.children)
            children = data.children.map((d, i) => {
                return element(d, parent, id + '-' + i, extra)
            }).filter(e => {
                return !(e === null || e === undefined)
            });
        let {onChange = null, ...props} = {...data.props};
        if (onChange)
            onChange = compileFunc(onChange)(data, parent, id, extra);
        content = <Form
            key={id}
            fields={fields}
            widgets={widgets}
            liveValidate={true}
            omitExtraData={true}
            formContext={Object.assign({parent: parent}, parent.props.registry.formContext)}
            liveOmit={true}
            children={children}
            onChange={onChange}
            {...props}
        />
    } else if (type === 'iframe') {
        content = <iframe key={id} title={id} className="iframe" {...props}/>
    } else if (data.children) {
        content = data.children.map((d, i) => {
            return element(d, parent, id + '-' + i, extra)
        }).filter(e => {
            return !(e === null || e === undefined)
        });
        let {onClick = null, onDoubleClick = null, ...newProps} = props;
        props = newProps;
        if (onClick)
            props.onClick = compileFunc(onClick).bind(null, data, parent, id, extra);
        if (onDoubleClick)
            props.onDoubleClick = compileFunc(onDoubleClick).bind(null, data, parent, id, extra);
        if (content.length) {
            if (type === 'carousel') {
                content =
                    <ControlledCarousel key={id} id={id}
                                        data={data} {...props}>{content}</ControlledCarousel>
            } else if (type === 'nav-item') {
                content =
                    <Navbar.Item key={id} {...props}>{content}</Navbar.Item>
            } else if (type === 'nav-link') {
                content =
                    <Navbar.Link key={id} {...props}>{content}</Navbar.Link>
            } else if (type === 'nav-dropdown') {
                content = <Navbar.Dropdown
                    key={id} {...props}>{content}</Navbar.Dropdown>
            } else if (type === 'nav-container') {
                content = <Navbar.Container
                    key={id} {...props}>{content}</Navbar.Container>
            } else if (type === 'section') {
                content = <Section key={id} {...props}>{content}</Section>
            } else if (type === 'dropdown-item') {
                content =
                    <Dropdown.Item key={id} {...props}>{content}</Dropdown.Item>
            } else if (type === 'dropdown-divider') {
                content = <Dropdown.Divider
                    key={id} {...props}>{content}</Dropdown.Divider>
            } else if (type === 'label') {
                content = <label key={id} {...props}>{content}</label>
            } else if (type === 'div') {
                content = <div key={id} {...props}>{content}</div>
            } else if (type === 'a') {
                content = <a key={id} {...props}>{content}</a>
            } else if (type === 'p') {
                content = <p key={id} {...props}>{content}</p>
            } else if (type === 'tr') {
                content = <tr key={id} {...props}>{content}</tr>
            } else if (type === 'td') {
                content = <td key={id} {...props}>{content}</td>
            } else if (type === 'th') {
                content = <td key={id} {...props}>{content}</td>
            } else if (type === 'span') {
                content = <span key={id} {...props}>{content}</span>
            }
        } else {
            content = null
        }
    } else {
        let name = data.id, {formData = {}} = parent.props;
        const {
            idSchema, uiSchema, errorSchema, disabled, readonly,
            onBlur, onFocus, idPrefix
        } = parent.props;

        if (data.hasOwnProperty('formData'))
            formData = data.formData
        const {rootSchema, fields} = parent.props.registry;
        const {SchemaField} = fields;
        let {schema = null, onPropertyChange = null, ...kw} = extra;
        schema = data.schema || schema || retrieveSchema(parent.props.schema, rootSchema);
        let onChange = parent.onPropertyChange ? parent.onPropertyChange(name) : onPropertyChange(name);
        if (data.onChange) {
            onChange = compileFunc(data.onChange)(onChange, parent);
        }
        if (schema.properties[name]) {
            if (data.hasOwnProperty('index')) {
                const index = Number(data.index)
                const itemIdPrefix = idSchema.$id + "_" + index;
                const item = formData[name][index]
                const itemIdSchema = toIdSchema(
                    schema.properties[name].items,
                    itemIdPrefix,
                    rootSchema,
                    item,
                    idPrefix
                );
                content = <SchemaField
                    key={id}
                    id={id}
                    name={name + '-' + index}
                    schema={schema.properties[name].items}
                    uiSchema={data.uiSchema || uiSchema[name].items}
                    errorSchema={(errorSchema[name] || {})[index]}
                    idSchema={data.idSchema || itemIdSchema}
                    formData={item}
                    onChange={(value) => {
                        let newArray = [...formData[name]];
                        newArray[index] = value;
                        onChange(newArray)
                    }}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    registry={parent.props.registry}
                    disabled={disabled}
                    parent={parent}
                    readonly={readonly} {...kw} {...props}/>
            } else {
                content = <SchemaField
                    key={id}
                    id={id}
                    name={name}
                    required={parent.isRequired ? parent.isRequired(name) : undefined}
                    schema={schema.properties[name]}
                    uiSchema={data.uiSchema || uiSchema[name]}
                    errorSchema={errorSchema[name]}
                    idSchema={data.idSchema || idSchema[name]}
                    formData={formData[name]}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    registry={parent.props.registry}
                    disabled={disabled}
                    parent={parent}
                    readonly={readonly} {...kw} {...props}/>
            }

        }
    }
    return content
}

export default class LayoutField extends ObjectField {
    render() {
        const {uiSchema, idSchema} = this.props;
        return element(uiSchema['ui:layout'], this, idSchema.$id)
    };
}
