import React from 'react'
import ObjectField
    from '@rjsf/core/lib/components/fields/ObjectField'
import {retrieveSchema} from "@rjsf/core/lib/utils";
import {
    Page,
    Text,
    View,
    Image,
    Document,
    Link,
    Font,
    pdf
} from '@react-pdf/renderer';
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Modal} from "react-bulma-components";
import {saveAs} from 'file-saver';
import font_regular from './Roboto-Regular.ttf'
import font_bolt from './Roboto-Bold.ttf'
import font_toyota_regular from './ToyotaType-Regular.ttf'
import font_toyota_bolt from './ToyotaType-Semibold.ttf'
import {element as layoutElement} from "./layout";
import ReactMarkdown from "react-markdown"
import jinja from 'jinja-js/jinja';

Font.register({
    family: 'Roboto',
    src: font_regular,
    weight: 'medium'
});
Font.register({
    family: 'Roboto',
    src: font_bolt,
    weight: 'bolt'
});
Font.register({
    family: 'Toyota-Font',
    src: font_toyota_regular,
    weight: 'medium'
});
Font.register({
    family: 'Toyota-Font',
    src: font_toyota_bolt,
    weight: 'bolt'
});

const hyphenationCallback = (word) => {
    return [word]
};

Font.registerHyphenationCallback(hyphenationCallback);

function getTitle(name, properties, rootSchema) {
    return (properties[name] !== undefined && retrieveSchema(properties[name], rootSchema).title) || name;
}

function getStyle(style, styles) {
    if (!Array.isArray(style)) {
        style = [style]
    }
    return Object.assign({}, ...style.map(s => {
        if (Array.isArray(s)) {
            s = getStyle(s, styles)
        } else if (typeof s === 'string' || s instanceof String) {
            s = styles[s]
        }
        return s || {}
    }));
}

function compileFunc(func) {
    if (typeof func === 'string')
        return new Function("return " + func)()
    return func
}

function element(data, styles, sections, formData, mainForm, schema, rootSchema, key) {
    data = Object.assign({}, sections[data.section] || {}, data);

    if (data.transformData)
        data = compileFunc(data.transformData)(data, formData, mainForm);
    let style = getStyle(data.style || {}, styles),
        props = data.props || {},
        type = data.type,
        content = formData.hasOwnProperty(data.id) ? formData[data.id] : (data.content || "");

    if (data.transformContent)
        content = compileFunc(data.transformContent)(content, formData, mainForm, data);
    if (data.markup)
        content = jinja.render(data.markup, {
            formData,
            mainForm,
            window,
            data
        }, {
            filters: {
                decimal: function (val, i, lang) {
                    let n = i ? Math.pow(10, i) : 1;
                    return (Math.round((val + Number.EPSILON) * n) / n).toLocaleString(lang || 'it');
                }
            }
        })
    if (data.domain && !compileFunc(data.domain)(content, formData, mainForm))
        return;

    if (props.render && (typeof props.render === 'string' || props.render instanceof String)) {
        props.render = compileFunc(props.render)
    }

    if (typeof content == 'number')
        content = Math.round(content).toLocaleString('it');
    if (type === 'page') {
        content = _pdf(data.children, styles, sections, formData, mainForm, schema, rootSchema, key + '-content');
        if (content.length || props.render)
            return <Page key={key}
                         style={getStyle(style, styles)} {...props}>{content}</Page>
    } else if (type === 'view') {
        content = _pdf(data.children, styles, sections, formData, mainForm, schema, rootSchema, key + '-content');
        if (content.length || props.render)
            return <View key={key}
                         style={getStyle(style, styles)} {...props}>
                {data.title ? <Text
                    key={key + '-title'}
                    style={getStyle([style.title, data["style.title"]], styles)} {...props.title}>
                    {data.title}
                </Text> : null}
                {content}
            </View>
    } else if (type === 'text' && data.children) {
        content = _pdf(data.children, styles, sections, formData, mainForm, schema, rootSchema, key + '-content');
        if (content.length)
            return <Text key={key} style={getStyle(style, styles)} {...props}>
                {data.prefix}{content}{data.suffix}
            </Text>;
        content = null
    }
    if (!content && !props.render) {

    } else if (type === 'image') {
        if (content)
            return <Image src={content} key={key}
                          style={getStyle(style, styles)} {...props}/>
    } else if (type === 'link') {
        if (content)
            return <Link src={content} key={key}
                         style={getStyle(style, styles)} {...props}>
                {content}
            </Link>
    } else if (type === 'text') {
        if (content) {
            content = (data.prefix || "") + content + (data.suffix || "");
            return <Text key={key} style={getStyle(style, styles)} {...props}>
                {content}
            </Text>
        }
    } else if (type === 'markdown') {

        if (content && data.args)
            content = content.format2(compileFunc(data.args)(data, formData))

        const TextMarkdown = (style, props) => {
            return <Text key={key} style={getStyle(style, styles)}>
                {props.children}
            </Text>
        }
        return <ReactMarkdown renderers={{
            paragraph: TextMarkdown.bind(null, 'paragraph'),
            strong: TextMarkdown.bind(null, 'bold'),
            heading: (props) => {
                return TextMarkdown(`heading-${props.level}`, props)
            }
        }}>
            {content}
        </ReactMarkdown>
    } else if (type === 'field') {
        content = (data.prefix || "") + content + (data.suffix || "");
        let title = data.title || getTitle(data.id, schema.properties, rootSchema);
        if (data.transformTitle)
            title = compileFunc(data.transformTitle)(title, formData);
        title = (data.titlePrefix || "") + title + (data.titleSuffix || "");
        return <View key={key}
                     style={getStyle([style.view, data["style.view"]], styles)} {...props.view}>
            <Text key={key + '-title'}
                  style={getStyle([style.title, data["style.title"]], styles)} {...props.title}>
                {title}
            </Text>
            <Text key={key + '-text'}
                  style={getStyle([style.text, data["style.text"]], styles)} {...props.text}>
                {content}
            </Text>
        </View>
    } else if (type === 'table') {
        let columns = new Set(), colStyle, col, title = data.title,
            items = data.items;
        if (!items) {
            items = retrieveSchema(schema.properties[data.id], rootSchema).items;
            items = retrieveSchema(items, rootSchema).properties;
        }
        (content || []).forEach(v => {
                Object.keys(v).forEach(k => {
                    if (v[k] !== null)
                        columns.add(k)
                })
            }
        );
        col = data.columns.filter(v => {
            return columns.has(v.id) || v.all
        });
        if (col.some(v => v.all)) {
            col.forEach(v => {
                columns.delete(v.id)
            });
            columns = Array.from(columns);
            columns.sort();
            col = col.reduce((res, v) => {
                if (v.all) {
                    columns.forEach(k => {
                        res.push(Object.assign({}, v, {id: k}))
                    })
                } else {
                    res.push(v)
                }
                return res
            }, [])
        }
        columns = col;
        if (!data.hasOwnProperty('defaultWidth') || data.defaultWidth)
            colStyle = {"width": (100 / columns.length) + "%"};
        if (!data.hasOwnProperty('header'))
            data.header = true;
        if (!data.hasOwnProperty('footer'))
            data.footer = true;
        if (!data.hasOwnProperty('title'))
            title = getTitle(data.id, schema.properties, rootSchema);
        if (title)
            title = (data.titlePrefix || "") + title + (data.titleSuffix || "");
        if (columns) {
            let n = columns.length - 1;
            return <View key={key}
                         style={getStyle([style.view, data["style.view"]], styles)} {...props.view}>
                {title ? <Text key={key + '-title'}
                               style={getStyle([style.title, data["style.title"]], styles)} {...props.title}>
                    {title}
                </Text> : null}
                <View key={key + '-table'}
                      style={getStyle([style.table, data["style.table"]], styles)}>
                    {data.header ? <View key={key + '-header'}
                                         style={getStyle([style.header, data["style.header"]], styles)}>
                        {columns.map((v, i) => {
                            let s = v.style || {}, extraColStyles = [],
                                extraCellStyles = [];
                            if (i === n) {
                                extraColStyles = [style.lastColumn, data["style.lastColumn"], s.lastColumn, style.lastColHeader, data["style.lastColHeader"], s.lastColHeader];
                                extraCellStyles = [style.lastCell, data["style.lastCell"], s.lastCell, style.lastCellHeader, data["style.lastCellHeader"], s.lastCellHeader];
                            }
                            return <View key={key + '-column-header-' + i}
                                         style={getStyle([colStyle, style.column, data["style.column"], s.column, style.colHeader, data["style.colHeader"], s.colHeader].concat(extraColStyles), styles)}>
                                <Text
                                    key={key + '-column-header-' + i + '-content'}
                                    style={getStyle([style.cellHeader, data["style.cellHeader"], s.cellHeader].concat(extraCellStyles), styles)}>
                                    {v.title || getTitle(v.id, items, rootSchema)}
                                </Text>
                            </View>
                        })}
                    </View> : null}
                    {(content || []).map((v, i) => {
                        let key_ = key + '-row-' + i, extraRowStyles,
                            row = (data.rows || {})[i] || {};

                        if (i % 2 === 0) {
                            extraRowStyles = [style.evenRow, data["style.evenRow"]]
                        } else {
                            extraRowStyles = [style.oddRow, data["style.oddRow"]]
                        }
                        extraRowStyles.push(row.style);
                        return <View key={key_}
                                     style={getStyle([style.row, data["style.row"]].concat(extraRowStyles), styles)}>
                            {columns.map((c, j) => {
                                let s = c.style || {},
                                    val = v.hasOwnProperty(c.id) ? v[c.id] : (c.empty || data.empty),
                                    extraColStyles = [], extraCellStyles = [];
                                if (j === n) {
                                    extraColStyles = [style.lastColumn, data["style.lastColumn"], s.lastColumn];
                                    extraCellStyles = [style.lastCell, data["style.lastCell"], s.lastCell];
                                }

                                if (typeof val == 'number') {
                                    val = Math.round(val) || c.empty || data.empty;
                                    if (typeof val == 'number') val = val.toLocaleString('it');
                                }
                                val = (c.valuePrefix || "") + (val || "") + (c.valueSuffix || "");
                                return <View key={key_ + '-' + j}
                                             style={getStyle([colStyle, style.column, data["style.column"], s.column, style.row, data["style.row"], s.row].concat(extraColStyles), styles)}>
                                    <Text key={key_ + '-' + j + '-text'}
                                          style={getStyle([style.cell, data["style.cell"], s.cell].concat(extraCellStyles), styles)}>
                                        {val}
                                    </Text>
                                </View>
                            })}
                        </View>
                    })}
                    {data.footer ? <View
                        key={key + '-footer'}
                        style={getStyle([style.footer, data["style.footer"]], styles)}>
                        {columns.map((v, i) => {
                            let footer = v.footer, s = v.style || {},
                                extraColStyles = [], extraCellStyles = [];
                            if (i === n) {
                                extraColStyles = [style.lastColumn, data["style.lastColumn"], s.lastColumn, style.lastColFooter, data["style.lastColFooter"], s.lastColFooter];
                                extraCellStyles = [style.lastCell, data["style.lastCell"], s.lastCell, style.lastCellFooter, data["style.lastCellFooter"], s.lastCellFooter];
                            }
                            if (v.footerCalculate) {
                                footer = compileFunc(v.footerCalculate)(content);
                            }
                            if (typeof footer == 'number') {
                                footer = Math.round(footer) || '-';
                                if (typeof footer == 'number') footer = footer.toLocaleString('it');
                            }
                            return <View key={key + '-column-footer-' + i}
                                         style={getStyle([colStyle, style.column, data["style.column"], s.column, style.colFooter, data["style.colFooter"], s.colFooter].concat(extraColStyles), styles)}>
                                <Text
                                    key={key + '-column-footer-' + i + '-content'}
                                    style={getStyle([style.cellFooter, data["style.cellFooter"], s.cellFooter].concat(extraCellStyles), styles)}>
                                    {footer}
                                </Text>
                            </View>
                        })}
                    </View> : null}
                </View>
            </View>
        }
    }
}

function _pdf(children, styles, sections, formData, mainForm, schema, rootSchema, key) {
    if (children !== undefined && formData !== undefined && Object.keys(formData).length !== 0) {
        return children.map((d, i) => {
            return element(d, styles, sections, formData, mainForm, schema, rootSchema, key + '-' + i);
        }).filter(e => {
            return !(e === null || e === undefined)
        })
    }
}

export default class PDFField extends ObjectField {
    render() {
        const {id, formData, uiSchema, registry} = this.props;
        const {rootSchema, fields, formContext} = registry, key = id;
        const schema = retrieveSchema(this.props.schema, rootSchema);
        const styles = uiSchema['ui:pdf'].styles || {};
        const {SchemaField} = fields;
        const sections = uiSchema['ui:pdf'].sections || {};
        const settings = uiSchema['ui:pdf'].settings || {};

        let onOpen = () => {
            let state = Object.assign({}, this.state);
            state[id] = true;
            this.setState(state, onRender);
        }, onClose = () => {
            let state = Object.assign({}, this.state);
            state[id] = false;
            this.setState(state);
        }, onRender = () => {
            let mainForm = formContext.getForm(),
                print = JSON.stringify(compileFunc(settings.get)(mainForm));
            if (!this.state.url || print !== this.state.print) {
                let state = Object.assign({}, this.state, {
                    loading: true,
                    blob: null,
                    url: null,
                    print: print
                });
                this.setState(state, () => {
                    setTimeout(() => {
                        let mainForm = formContext.getForm();
                        const content = _pdf(uiSchema['ui:pdf'].children, styles, sections, formData, mainForm, schema, rootSchema, key + "-document")
                        const document = <Document
                            key={key + '-pdf'}>
                            {content}
                        </Document>;
                        pdf(document).toBlob().then(blob => {
                            this.setState({
                                blob: blob,
                                url: URL.createObjectURL(blob),
                                loading: false
                            })
                        })
                    }, 500)
                })
            }
        }, onDownload = () => {
            if (this.state.blob)
                saveAs(this.state.blob, "document.pdf");
        };
        let print, mainForm = formContext.getForm();
        if (mainForm)
            print = JSON.stringify(compileFunc(settings.get)(mainForm));

        return <div key={id}{...uiSchema['ui:pdf'].props}>
            <div
                key={key + '-button'}
                className={"button is-rounded is-small" + (this.state.loading ? " is-loading" : "")}
                {...uiSchema['ui:pdf'].button}
                onClick={onOpen}>
                {uiSchema['ui:pdf'].icon ? layoutElement(uiSchema['ui:pdf'].icon, this, key + '-icon') :
                    <p>
                        <span key={key + '-icon'}>
                        <FontAwesomeIcon icon={faFilePdf}/>
                        </span>{" "}
                        <span key={key + '-title'}>{schema.title}</span>
                    </p>}
            </div>
            <Modal key={id + '-modal'} show={!!this.state[id]}
                   onClose={onClose} {...uiSchema['ui:pdf'].modal} closeOnBlur>
                <Modal.Content
                    key={id + '-content'} {...uiSchema['ui:pdf'].modalContent}>
                    <div
                        className="iframe-pdf columns is-vcentered is-centered">
                        <div className="column h-100">
                            {this.state.url ? <iframe
                                    className="has-background-white h-100 w-100"
                                    src={this.state.url}/> :
                                <div
                                    className="loader-wrapper is-active has-background-white h-100 w-100">
                                    <div className="loader"/>
                                </div>
                            }
                        </div>
                        <div
                            className="column has-background-white is-one-fifth h-100 scroll">
                            <SchemaField
                                key={id}
                                id={id}
                                schema={settings.schema || rootSchema}
                                uiSchema={settings.uiSchema}
                                onChange={(data) => {
                                    let mainForm = formContext.getForm();
                                    mainForm.setState({formData: data});
                                    this.setState({})
                                }}
                                formData={mainForm ? mainForm.state.formData : {}}
                                registry={this.props.registry}/>
                            {(this.state.print !== print) ?
                                <Button
                                    renderAs='div'
                                    className="is-rounded" size="small"
                                    color="primary"
                                    onClick={onRender}>Preview</Button> : (this.state.blob ?
                                    <Button
                                        renderAs='div'
                                        className="is-rounded" size="small"
                                        color="primary"
                                        onClick={onDownload}>Download</Button> : null)}
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    }
}