import React, {Component} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import bulmaCalendar from 'bulma-calendar'

export default class DatePicker extends Component {
    componentDidMount() {
        const calendar = bulmaCalendar.attach(`[id="${this.props.id}"]`, {
            dateFormat: "DD/MM/YYYY",
            lang: "it",
            showClearButton: false
        })[0], {onChange, value} = this.props;
        if (value) {
            calendar.datePicker.value(moment(value, "YYYY-MM-DD"));
        } else {
            calendar.datePicker.value(moment());
        }
        calendar.datePicker.refresh();
        calendar.refresh();
        calendar.save();
        calendar.on('select', datepicker => {
            onChange(moment(datepicker.data.value(), "DD/MM/YYYY").format("YYYY-MM-DD"));
        });
    }

    render() {
        return (<input id={this.props.id} type="date"/>);
    }
}

DatePicker.propTypes = {
    schema: PropTypes.shape({
        format: PropTypes.oneOf(["date"]),
    }),
};
