import React from "react";
import {Modal} from "react-bulma-components";
import {retrieveSchema} from "@rjsf/core/lib/utils";
import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";
import {shouldRender} from "@rjsf/core/lib/utils";
import _ from "lodash"

export default class ListinoWidget extends SchemaField {
    constructor(props) {
        super(props);
        this.state = {'data': []}
    }

    shouldComponentUpdate(nextProps, nextState) {
        return shouldRender(this, nextProps, nextState);
    }

    getValue(item) {
        return ['make', 'model', 'type', 'year'].map(k => (item[k])).join(', ')
    }

    setValue(item) {
        let id = this.props.idSchema.$id,
            k = id.split('_')[2],
            form = this.props.registry.formContext.getForm(),
            state = _.cloneDeep(form.state),
            beni_servizi = state.formData.offerte[k].oggetto.beni_servizi.slice();
        state.formData.offerte[k].oggetto.beni_servizi = beni_servizi.map((v, i) => {
            if (i === 0)
                v = Object.assign({}, v, {imponibile_pre_sconto: Number(item.prezzo_listino)});
            return v
        });
        form.setState(state, () => {
            let state = Object.assign({}, this.state, {data: []});
            state[id] = false;
            this.setState(state, () => {
                this.props.onChange(this.getValue(item))
            })
        })
    }

    render() {
        const {idSchema, uiSchema, formData, registry} = this.props;
        const {rootSchema, fields, formContext} = registry;
        const {DescriptionField, SchemaField} = fields;
        const schema = retrieveSchema(this.props.schema, rootSchema);
        let title, id = idSchema.$id;
        if (uiSchema !== undefined && uiSchema.hasOwnProperty('ui:title')) {
            title = uiSchema['ui:title'];
        } else {
            title = (schema.title === undefined) ? '' : schema.title;
        }

        let onOpen = async () => {
            let state = Object.assign({}, this.state), getValue = this.getValue;
            state[id] = true;
            window.listino.sort(function (x, y) {
                return getValue(x) === formData ? -1 : getValue(y) === formData ? 1 : 0;
            });
            state.data=window.listino
            this.setState(state);
        }, onClose = () => {
            let state = Object.assign({}, this.state, {data: []});
            state[id] = false;
            this.setState(state);
        };
        if (formData === "undefined")
            this.setValue(window.listino[0]);
        return (
            <div key={`${idSchema.$id}__container`}>
                {title ? <label className="control-label" htmlFor={id}>
                    {title}
                </label> : null}
                {schema.description ?
                    <DescriptionField
                        id={`${idSchema.$id}__description`}
                        description={schema.description}
                        formContext={formContext}/> : null}
                <input key={id} id={id} className="form-control"
                       onClick={onOpen} onChange={() => {
                }}
                       type="text"
                       value={typeof formData === "undefined" ? '' : formData}/>
                <Modal key={id + '-modal'} show={!!this.state[id]}
                       onClose={onClose} closeOnBlur>
                    <Modal.Card key={id + '-card'}
                                className={'modal-card-listini'}>
                        <Modal.Card.Head
                            onClose={onClose}
                            key={id + '-card-head'}>
                            <Modal.Card.Title key={id + '-card-head-title'}>
                                Listino
                            </Modal.Card.Title>
                        </Modal.Card.Head>
                        <Modal.Card.Body key={id + '-card-body'}
                                         className="vertical-wrapper no-overflow">
                            <SchemaField
                                key={id + '-table'}
                                id={id + '-table'}
                                schema={{"$ref": "#/definitions/listino"}}
                                uiSchema={uiSchema['ui:listino']}
                                onChange={() => {
                                }}
                                formData={this.state.data}
                                registry={this.props.registry}
                                parent={this}
                            />
                        </Modal.Card.Body>
                    </Modal.Card>
                </Modal>
            </div>
        )
    }
}
