import React from "react";
import ArrayField from '@rjsf/core/lib/components/fields/ArrayField'
import shortid from "shortid";
import {
    getDefaultRegistry,
    retrieveSchema, shouldRender, toIdSchema
} from '@rjsf/core/lib/utils'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faClone,
    faPlus,
    faEllipsisV,
    faChevronLeft,
    faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import {
    faArrowAltCircleUp,
    faArrowAltCircleDown
} from "@fortawesome/free-regular-svg-icons";
import {
    ReflexContainer, ReflexSplitter, ReflexElement
} from 'react-reflex'
import {element} from './layout'
import _ from "lodash"

function compileFunc(func) {
    if (typeof func === 'string') {
        return new Function("return " + func)()
    }
    return func
}

function generateRowId() {
    return shortid.generate();
}

function ArrayFieldTitle({TitleField, idSchema, title, required, className}) {
    if (!title)
        return;
    const id = `${idSchema.$id}__title`;
    return <div className={className} id={id}>{title}</div>;
}

function ArrayFieldDescription({DescriptionField, idSchema, description}) {
    if (!description)
        return;
    const id = `${idSchema.$id}__description`;
    return <DescriptionField id={id} description={description}/>;
}

function keyedToPlainFormData(keyedFormData) {
    return keyedFormData.map(keyedItem => keyedItem.item);
}

function setActive(parent, index, callback) {
    let state = Object.assign({}, parent.state);
    state['active'] = index;
    parent.setState(state, callback);
}

export default class LateralArray extends ArrayField {
    shouldComponentUpdate(nextProps, nextState) {
        return shouldRender(this, nextProps, nextState);
    }

    renderArrayFieldItem(props) {
        const {
            key,
            index,
            canRemove = true,
            canMoveUp = true,
            canMoveDown = true,
            itemSchema,
            itemData,
            itemUiSchema,
            itemIdSchema,
            itemErrorSchema,
            autofocus,
            onBlur,
            onFocus,
            rawErrors,
            name
        } = props;
        const {
            disabled,
            readonly,
            uiSchema,
            registry = getDefaultRegistry(),
        } = this.props;
        const {
            fields: {SchemaField},
        } = registry;
        const {orderable, removable} = {
            orderable: true,
            removable: true,
            ...uiSchema["ui:options"],
        };
        const has = {
            moveUp: orderable && canMoveUp,
            moveDown: orderable && canMoveDown,
            remove: removable && canRemove,
        };
        has.toolbar = Object.keys(has).some(key => has[key]);
        if (!itemData.hasOwnProperty(name.id))
            itemData[name.id] = name.default.format2([index + 1]);
        return {
            children: (
                <SchemaField
                    key={itemIdSchema + '-' + index}
                    index={index}
                    schema={itemSchema}
                    uiSchema={itemUiSchema}
                    formData={itemData}
                    errorSchema={itemErrorSchema}
                    idSchema={itemIdSchema}
                    required={this.isItemRequired(itemSchema)}
                    onChange={this.onChangeForIndex(index)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    registry={this.props.registry}
                    disabled={this.props.disabled}
                    readonly={this.props.readonly}
                    autofocus={autofocus}
                    parent={this}
                    rawErrors={rawErrors}
                />
            ),
            name: (<div key={itemIdSchema + '-' + index + '-name-field-container'} {...name.props}>
                <SchemaField
                    key={itemIdSchema + '-' + index + '-name-field'}
                    index={index}
                    schema={itemSchema.properties[name.id]}
                    uiSchema={name.uiSchema || itemUiSchema[name.id]}
                    formData={itemData[name.id]}
                    errorSchema={itemErrorSchema ? itemErrorSchema[name.id] : undefined}
                    idSchema={itemIdSchema[name.id]}
                    required={this.isItemRequired(itemSchema.properties[name.id])}
                    onChange={(value) => {
                        let d = _.cloneDeep(this.state.keyedFormData[index].item);
                        d[name.id] = value;
                        this.onChangeForIndex(index)(d)
                    }
                    }
                    registry={this.props.registry}
                    rawErrors={rawErrors}

                /></div>
            ),
            className: "array-item",
            disabled,
            hasToolbar: has.toolbar,
            hasMoveUp: has.moveUp,
            hasMoveDown: has.moveDown,
            hasRemove: has.remove,
            index,
            key,
            onAddIndexClick: this.onAddIndexClick,
            onDropIndexClick: this.onDropIndexClick,
            onReorderClick: this.onReorderClick,
            readonly,
        };
    }

    getActive = () => {
        let active = this.state['active'] || (this.state.keyedFormData.length ? this.state.keyedFormData[0].key : null);
        if (active)
            this.state.keyedFormData.forEach((d, i) => {
                if (active === d.key)
                    active = {key: active, index: i}
            });
        return active
    };
    previousItem = (n = -1) => {
        let length = this.state.keyedFormData.length, active = this.getActive();
        if (active) {
            let i = active.index + n;
            if (i >= 0 && i < length)
                this.setState(Object.assign({}, this.state, {active: this.state.keyedFormData[i].key}))
        }

    };
    copyActive = () => {
        let active = this.state['active'] || (this.state.keyedFormData.length ? this.state.keyedFormData[0].key : null);
        if (active)
            this.state.keyedFormData.forEach((d, i) => {
                if (active === d.key)
                    this.onCopyIndexClick(i)()
            })
    };

    onCopyIndexClick = (index) => {
        return event => {
            if (event) {
                event.preventDefault();
            }
            const {onChange, uiSchema} = this.props;
            let newKeyedFormData = [...this.state.keyedFormData],
                item = _.cloneDeep(newKeyedFormData[index].item);
            item[uiSchema["ui:lateralArray"].name.id] += ' - ' + window.lang('$copy');
            const newKeyedFormDataRow = {
                key: generateRowId(),
                item: item,
            };
            newKeyedFormData.splice(index + 1, 0, newKeyedFormDataRow);
            setActive(this, newKeyedFormDataRow.key);
            this.setState(
                {
                    keyedFormData: newKeyedFormData,
                    updatedKeyedFormData: true,
                },
                () => onChange(keyedToPlainFormData(newKeyedFormData))
            );
        };
    };

    onAddClick = event => {
        if (event) {
            event.preventDefault();
        }

        const {onChange} = this.props;
        const newKeyedFormDataRow = {
            key: generateRowId(),
            item: this._getNewFormDataRow(),
        };
        const newKeyedFormData = [...this.state.keyedFormData, newKeyedFormDataRow];
        this.setState(
            {
                active: newKeyedFormDataRow.key,
                keyedFormData: newKeyedFormData,
                updatedKeyedFormData: true,
            },
            () => onChange(keyedToPlainFormData(newKeyedFormData))
        );
    };

    renderNormalArray() {
        const {
            schema,
            uiSchema,
            errorSchema,
            idSchema,
            name,
            required,
            disabled,
            readonly,
            autofocus,
            registry = getDefaultRegistry(),
            onBlur,
            onFocus,
            idPrefix,
            rawErrors,
        } = this.props;
        const options = uiSchema["ui:lateralArray"];
        const title = schema.title === undefined ? name : schema.title;
        const {rootSchema, fields, formContext} = registry;
        const {TitleField, DescriptionField} = fields;
        const itemsSchema = retrieveSchema(schema.items, rootSchema);
        const formData = keyedToPlainFormData(this.state.keyedFormData);
        const arrayProps = {
            canAdd: this.canAddItem(formData),
            items: this.state.keyedFormData.map((keyedItem, index) => {
                const {key, item} = keyedItem;
                const itemSchema = retrieveSchema(schema.items, rootSchema, item);
                const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;
                const itemIdPrefix = idSchema.$id + "_" + index;
                const itemIdSchema = toIdSchema(
                    itemSchema,
                    itemIdPrefix,
                    rootSchema,
                    item,
                    idPrefix
                );
                return this.renderArrayFieldItem({
                    key,
                    index,
                    canMoveUp: index > 0,
                    canMoveDown: index < formData.length - 1,
                    itemSchema: itemSchema,
                    itemIdSchema,
                    itemErrorSchema,
                    itemData: item,
                    itemUiSchema: uiSchema.items,
                    autofocus: autofocus && index === 0,
                    onBlur,
                    onFocus,
                    name: options.name
                });
            }),
            className: `field field-array field-array-of-${itemsSchema.type}`,
            DescriptionField,
            disabled,
            idSchema,
            uiSchema,
            readonly,
            required,
            schema,
            title,
            TitleField,
            formContext,
            formData,
            rawErrors,
            registry,
        };
        const {orderable, removable, copy} = {
            orderable: true,
            removable: true,
            copy: true,
            ...uiSchema["ui:options"],
        };

        let key = `array-lateral-${arrayProps.idSchema.$id}`,
            filterItems = () => true,
            active = this.state['active'] || (this.state.keyedFormData.length ? this.state.keyedFormData[0].key : null),
            itemName = options.itemName || "Elemento",
            reflexMenu = {flex: .2, ...options.reflexMenu};
        if (this.state.menuClosed){
            reflexMenu.flex = 0
            reflexMenu.maxSize = .01
            reflexMenu.minSize = 0
        }
        if (options.filterItems)
            filterItems = compileFunc(options.filterItems)(options, this, key, active);
        return (
            <ReflexContainer
                orientation="vertical"
                className={'h-100 is-marginless ' + arrayProps.className}
                id={arrayProps.idSchema.$id} key={key + '-all'}>
                <ReflexElement
                    key={key + '-menu'}  {...reflexMenu}>
                    <div
                        className="array-control vertical-wrapper array-menu">
                        <div
                            className="is-flex level array-control-bar card-header-title">
                            {orderable ?
                                <div
                                    className="array-control-orderable">
                                    <div
                                        key={key + '-up'}
                                        className="array-control-orderable-div has-tooltip-arrow has-tooltip-right has-tooltip-bottom"
                                        data-tooltip={window.lang("$Muovi in alto l'oggetto corrente")}
                                        onClick={(e) => {
                                            let i;
                                            if (!this.state['active'])
                                                setActive(this, active);
                                            this.state.keyedFormData.forEach((element, j) => {
                                                if (active === element.key)
                                                    i = j
                                            });
                                            if (i) {
                                                let j = i - 1;
                                                this.onReorderClick(i, j)(e);
                                            }
                                        }}>
                                        <FontAwesomeIcon
                                            key={key + '-up-icon'}
                                            icon={faArrowAltCircleUp}
                                            className="is-clickable array-control-orderable-icon action-icon"/>
                                    </div>
                                    <div
                                        key={key + '-down'}
                                        className="array-control-orderable-div has-tooltip-arrow has-tooltip-right has-tooltip-bottom"
                                        data-tooltip={window.lang("$Muovi in basso l'oggetto corrente")}
                                        onClick={(e) => {
                                            let i = 0;
                                            if (!this.state['active'])
                                                setActive(this, active);
                                            this.state.keyedFormData.forEach((element, j) => {
                                                if (active === element.key)
                                                    i = j
                                            });
                                            if (i !== arrayProps.items.length - 1) {
                                                let j = i + 1;
                                                this.onReorderClick(i, j)(e);
                                            }
                                        }}>
                                        <FontAwesomeIcon
                                            key={key + '-down-icon'}
                                            className="is-clickable array-control-orderable-icon action-icon"
                                            icon={faArrowAltCircleDown}/>
                                    </div>
                                </div> : null}
                            <ArrayFieldTitle
                                key={`array-field-title-${arrayProps.idSchema.$id}`}
                                TitleField={arrayProps.TitleField}
                                idSchema={arrayProps.idSchema}
                                title={arrayProps.uiSchema["ui:title"] || arrayProps.title}
                                required={arrayProps.required}
                                className="has-text-centered level-item"
                            />
                            {(arrayProps.uiSchema["ui:description"] || arrayProps.schema.description) && (
                                <ArrayFieldDescription
                                    key={`array-field-description-${arrayProps.idSchema.$id}`}
                                    DescriptionField={arrayProps.DescriptionField}
                                    idSchema={arrayProps.idSchema}
                                    description={
                                        arrayProps.uiSchema["ui:description"] || arrayProps.schema.description
                                    }
                                />
                            )}
                            {options.menu_buttons ? element(options.menu_buttons, this, key + '-menu-buttons') : null}
                        </div>
                        <ul className="menu-list scroll" key={key + '-menu'}>
                            {arrayProps.items.map((element, i) => {
                                let className = "array-index-icon is-clickable level-left has-tooltip-arrow has-tooltip-right has-tooltip-bottom",
                                    r = removable,
                                    tooltip = window.lang('$Seleziona')+' ' + itemName;
                                if (removable && options.hasOwnProperty('removableFunc'))
                                    r = compileFunc(options.removableFunc)(i, formData);
                                if (element.key === active) {
                                    className += ' is-active';
                                    tooltip = itemName + ' '+ window.lang('$Corrente')
                                }
                                return <li
                                    key={key + '-menu-row-' + element.key}
                                    className="level is-mobile is-marginless">
                                    <div className={className + ' '}
                                         onClick={() => {
                                             setActive(this, element.key)
                                         }}
                                         data-tooltip={tooltip}
                                         key={key + '-id-' + element.key}>{i + 1}</div>
                                    {element.name}
                                    <div
                                        key={key + '-buttons-' + element.key}
                                        className="field is-grouped level-right">
                                        {copy ? <span
                                            key={key + '-copy-' + element.key}
                                            className="icon has-background-primary action-icons small-icon is-clickable has-tooltip-arrow has-tooltip-left has-tooltip-bottom"
                                            data-tooltip={window.lang("$Copia")+" " + itemName}
                                            onClick={(e) => {
                                                this.onCopyIndexClick(i)(e);
                                            }}>
                                    <FontAwesomeIcon icon={faClone}/>
                                </span> : null}
                                        {r ? <span
                                            key={key + '-remove-' + element.key}
                                            className="icon has-background-danger action-icons small-icon is-clickable has-tooltip-arrow has-tooltip-left has-tooltip-bottom"
                                            data-tooltip={window.lang("$Rimuovi")+" " + itemName}
                                            onClick={(e) => {
                                                if (element.key === active) {
                                                    let k = i === 0 ? 1 : i - 1;
                                                    setActive(this, this.state.keyedFormData[k].key)
                                                }
                                                this.onDropIndexClick(i)(e);
                                            }}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </span> : null}
                                    </div>
                                </li>

                            })}
                            {arrayProps.canAdd && (
                                <li key={key + '-menu-add'}
                                    className="level is-marginless">
                                <span
                                    key={key + '-add'}
                                    className="icon has-background-primary action-icons is-clickable lateral-array-item-add has-tooltip-arrow has-tooltip-top"
                                    data-tooltip={window.lang("$Aggiungi")+ " " + itemName}
                                    onClick={this.onAddClick}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </span>
                                </li>
                            )}
                        </ul>
                    </div>
                </ReflexElement>
                <ReflexSplitter
                    key={key + '-splitter'}
                    className="is-flex array-control-splitter" {...options.menuSplitter}>
                    <span key={key + '-slpitter-button'}
                          className="icon is-clickable array-control-splitter-button"
                          onClick={(e) => {
                              e.preventDefault();
                              let state = Object.assign({}, this.state);
                              state.menuClosed = !state.menuClosed;
                              this.setState(state)
                          }}>
                        <div
                            className="has-tooltip-arrow has-tooltip-right has-tooltip-bottom"
                            data-tooltip={window.lang(!!this.state.menuClosed ? "$Apri Menu" : "$Chiudi Menu")}>
                        <FontAwesomeIcon
                            key={key + '-slpitter-button-icon'}
                            icon={!!this.state.menuClosed ? faChevronRight : faChevronLeft}
                        /></div>
                    </span>
                    {!this.state.menuClosed ?
                        <div
                            className="col-resize has-tooltip-arrow has-tooltip-right has-tooltip-bottom"
                            data-tooltip={window.lang("$Ridimensiona il Menu")}>
                            <FontAwesomeIcon
                                key={key + '-drag'} className="w-100 "
                                icon={faEllipsisV}/>
                        </div> : null
                    }
                </ReflexSplitter>
                <ReflexElement
                    className="is-paddingless array-content"
                    key={key + '-content'} {...options.reflexArray}>
                    {arrayProps.items &&
                    arrayProps.items.filter(filterItems).map(e => {
                        return <div
                            key={e.key + '-container'}
                            className={e.key !== active ? 'is-hidden' : 'h-100'}>
                            {e.children}
                        </div>
                    })}
                </ReflexElement>
            </ReflexContainer>
        );
    }
};