import React from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

function compileFunc(func) {
    if (typeof func === 'string')
        return new Function("return " + func)()
    return func
}

function ReCAPTCHAWidget(props) {
    let {
        schema,
        id,
        disabled,
        readonly,
        options,
        onChange,
        DescriptionField,
    } = props;
    if (options.onChange)
        onChange = compileFunc(options.onChange)(onChange);
    return (
        <div
            className={`field ${disabled || readonly ? "disabled" : ""}`}>
            {schema.description && (
                <DescriptionField description={schema.description}/>
            )}
            <ReCAPTCHA
                key={id}
                sitekey="6LeICskZAAAAAIElypFgyJ1Zu7RMlfFBZNqVyB9z"
                onChange={onChange}
                hl={'it'}
            />
        </div>
    );
}


if (process.env.NODE_ENV !== "production") {
    ReCAPTCHAWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        value: PropTypes.bool,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        onChange: PropTypes.func,
    };
}

export default ReCAPTCHAWidget;